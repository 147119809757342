import Delete from '@mui/icons-material/Delete'
import Replay from '@mui/icons-material/Replay'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmRemoveFileDialog from './ConfirmRemoveFileDialog'

interface Props {
  icon: JSX.Element
  label: JSX.Element | string
  downloadURL: string
  downloadName?: string
  onRemoveClick(): void
  isSubmitting: boolean
  isRemovingFile?: boolean
  isSaved: boolean
  hasError?: boolean
  retrySubmit?: () => Promise<void>
  removeFile(): void
}

export default function File(props: Props): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()

  const onFileClick = (): void => {
    if (!props.hasError) {
      window.open(props.downloadURL)
    }
  }

  return (
    <ListItemButton classes={{ root: classes.width100 }}>
      <ListItemIcon>{props.icon}</ListItemIcon>

      <ListItemText
        onClick={onFileClick}
        primary={<Typography color={props.hasError ? 'error' : 'primary'}>{props.label}</Typography>}
        color="error"
      />

      {props.isSubmitting && (
        <Stack columnGap={5} direction="row" alignItems="center">
          <Typography>{t('uploading')}...</Typography>
          <CircularProgress />
        </Stack>
      )}

      {props.isRemovingFile && (
        <Stack columnGap={5} direction="row" alignItems="center">
          <Typography>{t('removing')}...</Typography>
          <CircularProgress />
        </Stack>
      )}

      {props.hasError && (
        <Stack columnGap={5} direction="row" alignItems="center">
          <Typography color="error">{t('anErrorOccurred')}</Typography>

          <Button startIcon={<Delete />} data-testid='removeButton' variant="outlined" color="error" onClick={props.removeFile}>
            {t('remove')}
          </Button>

          <Button startIcon={<Replay />} data-testid='replayButton' variant="outlined" color="error" onClick={props.retrySubmit}>
            {t('retry')}
          </Button>
        </Stack>
      )}

      {props.isSaved && !props.isRemovingFile && !props.isSubmitting && (
        <Stack columnGap={5} direction="row" alignItems="center">
          <Typography>{t('saved')}</Typography>
          <ConfirmRemoveFileDialog onRemove={props.onRemoveClick} />
        </Stack>
      )}
    </ListItemButton>
  )
}

const useStyles = makeStyles({
  width100: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
})
