import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

export interface Props extends BoxProps<'form'> {
  isSubmitDisabled: boolean
  leftPanel: React.ReactNode
  rightPanel: React.ReactNode
}

export default React.forwardRef<HTMLFormElement, Props>(function Wrapper(props, formRef): JSX.Element {
  const { isSubmitDisabled, leftPanel, rightPanel, ...boxProps } = props

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Box {...boxProps} component="form" ref={formRef}>
      <Helmet>
        <title>
          {t('accountInformation')} • {t('businessAssetsFiling')} • {t('personalProperty')}
        </title>
      </Helmet>

      <Stack spacing={3}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6}>
            <Box className={classes.fullWidth}>{leftPanel}</Box>
          </Grid>
          <Grid container item xs={12} md={6}>
            {rightPanel}
          </Grid>
        </Grid>

        <Box display="flex" flex="1" justifyContent="flex-end">
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitDisabled}>
            {t('confirmInformation')}
          </Button>
        </Box>
      </Stack>
    </Box>
  )
})

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
})
