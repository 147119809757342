import { Stack, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { Maybe, Summary, FileExtension, FileInfo } from '../../generated/telecomGraphqlService'
import { FileExt } from '../ThankYouPage/sections/FileTile'
import SummaryFile from './SummaryFile'

interface UploadedFilesProps {
  summary: Summary
}

export default function UploadedFiles(props: UploadedFilesProps): JSX.Element {
  const classes = useStyles()
  const { leasedAssetListURL, propertyAssetListURL } = props.summary
  const [nonLeasefileExt, setNonLeasefileExt] = useState<FileExt>(FileExt.pdf)
  const [leaseFileExt, setLeaseFileExt] = useState<FileExt>(FileExt.pdf)

  const nonLeasedFile = React.useMemo((): FileInfo => {
    return nonLeasefileExt === FileExt.pdf
      ? (propertyAssetListURL?.pdf as FileInfo)
      : (propertyAssetListURL?.csv as FileInfo)
  }, [nonLeasefileExt, propertyAssetListURL?.csv, propertyAssetListURL?.pdf])

  const leasedFile = React.useMemo((): FileInfo => {
    return leaseFileExt === FileExt.pdf
      ? (leasedAssetListURL?.pdf as FileInfo)
      : (leasedAssetListURL?.csv as FileInfo)
  }, [leaseFileExt, leasedAssetListURL?.csv, leasedAssetListURL?.pdf])

  const validateURL = (urlObj: Maybe<FileExtension> | undefined): boolean => {
    return Boolean(urlObj && (urlObj.pdf?.fileUrl || urlObj.csv?.fileUrl))
  }
  const onNonLeaseFileExtChange = (value: Maybe<string>): void => {
    if (value) {
      setNonLeasefileExt(value as FileExt)
    }
  }
  const onLeaseFileExtChange = (value: Maybe<string>): void => {
    if (value) {
      setLeaseFileExt(value as FileExt)
    }
  }

  return (
    <Stack
      spacing={2}
      p={1}
      display="flex"
      justifyContent="center"
      direction="row"
      marginY={2}
      paddingX={3}
      width="100%"
    >
      {validateURL(propertyAssetListURL) ? (
        <Box className={classes.textTruncate}>
          <SummaryFile
            filename={nonLeasedFile?.filename as string}
            href={nonLeasedFile?.fileUrl as string}
            setExtension={onNonLeaseFileExtChange}
            extension={nonLeasefileExt}
          />
        </Box>
      ) : null}
      {validateURL(leasedAssetListURL) ? (
        <Box className={classes.textTruncate}>
          <SummaryFile
            filename={leasedFile?.filename as string}
            href={leasedFile?.fileUrl as string}
            setExtension={onLeaseFileExtChange}
            extension={leaseFileExt}
          />
        </Box>
      ) : null}
    </Stack>
  )
}

const useStyles = makeStyles(() => ({
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))
