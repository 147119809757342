import { Theme } from '@mui/material'
import MuiStepper from '@mui/material/Stepper'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

import { BusinessAssetsFilingStep } from '../../models/api'
import {
  goToAccountInformation,
  goToPropertyAssetList,
  goToLeasedAssetList,
  goToSummaryAndSignature,
} from '../../utils/routeActions'
import Step from './Step'

export interface Props {
  activeStep: number
  isAccountInformationComplete?: boolean
  isPropertyAssetListComplete?: boolean
  isLeasedAssetListComplete?: boolean
}

export default function Stepper(props: Props): JSX.Element {

  const navigate = useNavigate()
  const { user } = useAuth()
  const { t } = useTranslation()

  const activeStep = props.activeStep

  const classes = useStyles()

  const completedSteps = React.useMemo(() => {
    return user?.completedSteps.reduce(selectCompletedStep, {})
  }, [user?.completedSteps])

  const isFirstStepComplete = completedSteps?.isAccountInformationComplete
  const isSecondStepComplete = isFirstStepComplete && completedSteps?.isPropertyAssetListComplete
  const isThirdStepComplete = isSecondStepComplete && completedSteps?.isLeasedAssetListComplete

  const onAccountInformationClick = (): void => {
    goToAccountInformation(navigate)
  }

  const onPropertyAssetListClick = (): void => {
    goToPropertyAssetList(navigate)
  }

  const onLeasedAssetListClick = (): void => {
    goToLeasedAssetList(navigate)
  }

  const onSummaryAndSignatureClick = (): void => {
    goToSummaryAndSignature(navigate)
  }

  return (
    <MuiStepper
      activeStep={activeStep}
      alternativeLabel
      nonLinear
      classes={classes}
    >
      <Step
        label={t('accountInformation')}
        activeStep={activeStep}
        thisStep={0}
        isCompleted={isFirstStepComplete}
        onClick={onAccountInformationClick}
      />
      <Step
        label={t('propertyAssetList')}
        activeStep={activeStep}
        thisStep={1}
        isCompleted={isSecondStepComplete}
        isDisabled={!isFirstStepComplete}
        onClick={onPropertyAssetListClick}
      />
      <Step
        label={t('leasedAssetList')}
        activeStep={activeStep}
        thisStep={2}
        isCompleted={isThirdStepComplete}
        isDisabled={!isSecondStepComplete}
        isOptional
        onClick={onLeasedAssetListClick}
      />
      <Step
        label={t('summaryAndSignature')}
        activeStep={activeStep}
        thisStep={3}
        isDisabled={!isThirdStepComplete}
        onClick={onSummaryAndSignatureClick}
      />
    </MuiStepper>
  )
}

type CompletedSteps = Partial<Props>

function selectCompletedStep(alreadyCompleted: CompletedSteps, step: BusinessAssetsFilingStep): CompletedSteps {
  switch (step) {
    case BusinessAssetsFilingStep.PropertyAssetList: {
      return { ...alreadyCompleted, isPropertyAssetListComplete: true }
    }

    case BusinessAssetsFilingStep.LeasedAssetList: {
      return { ...alreadyCompleted, isLeasedAssetListComplete: true }
    }
  }

  return { ...alreadyCompleted, isAccountInformationComplete: true }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))
