import React from 'react'
import { Navigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { nextStep } from '../../utils/nextStep'
import paths from '../../utils/paths'

export default function PageNotFound(): JSX.Element {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to={paths.login} />
  }

  return <Navigate to={nextStep(user.completedSteps)} />
}
