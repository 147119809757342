import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Transition from '../Transition'

interface Props {
  onRemove: () => void
}

export default function ConfirmRemoveFileDialog(props: Props): JSX.Element {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleOnRemove = (): void => {
    handleClose()
    props.onRemove()
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} data-testid="delete-button">
        <CloseIcon />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="remove-file-dialog"
      >
        <DialogTitle>{t('removeFile')}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-file-dialog">
            {t('removeFileDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">{t('close')}</Button>
          <Button onClick={handleOnRemove} color="primary">{t('remove')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
