import { Button, List, ListItem, ListItemText, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ORDINAL_DATE_FORMAT } from '../../../utils/constants'

export interface PaymentInformationProps {
  deadline: Date
  interestRate: string
  appealDeadline: Date
  paymentDeadline: Date
  countyContactsURL: string
  penaltyPercent?: string
}

export default function PaymentInformation(props: PaymentInformationProps): JSX.Element {
  const { t } = useTranslation()

  const { interestRate, countyContactsURL, penaltyPercent } = props
  const deadline = format(props.deadline, ORDINAL_DATE_FORMAT)
  const appealDeadline = format(props.appealDeadline, ORDINAL_DATE_FORMAT)
  const paymentDeadline = format(props.paymentDeadline, ORDINAL_DATE_FORMAT)

  return (
    <Stack flex={2}>
      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
        {t('paymentInformation')}
      </Typography>

      <List sx={{ listStyleType: 'disc', paddingLeft: 2 }}>
        <ListItem sx={{ display: 'list-item' }} disablePadding>
          <ListItemText>{t('thankYouPaymentInformationLine1')}</ListItemText>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }} disablePadding>
          <ListItemText>{t('thankYouPaymentInformationLine2', { deadline })}</ListItemText>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }} disablePadding>
          <ListItemText>{t('thankYouPaymentInformationLine3', { paymentDeadline })}</ListItemText>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }} disablePadding>
          <ListItemText>{t('thankYouPaymentInformationLine4', { penaltyPercent, deadline })}</ListItemText>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }} disablePadding>
          <ListItemText>{t('thankYouPaymentInformationLine5', { interestRate })}</ListItemText>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }} disablePadding>
          <ListItemText>{t('thankYouPaymentInformationLine6', { appealDeadline })}</ListItemText>
        </ListItem>
      </List>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          href={countyContactsURL}
          download={countyContactsURL}
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          disabled={!countyContactsURL}
        >
          {t('countyContacts')}
        </Button>
      </Box>
    </Stack>
  )
}
