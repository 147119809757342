import z from 'zod'

import * as p from './parsers'

export const zAddress = z.object({
  inCareOf: p.nonEmptyString().nullable().optional(),
  addressLine1: p.nonEmptyString(),
  addressLine2: p.nonEmptyString().nullable().optional(),
  city: p.nonEmptyString().nullable(),
  state: p.state(),
  zipCode: p.zipCode(),
})

export type AddressType = z.infer<typeof zAnyContact>

const zContact = z.object({
  id: p.nonEmptyString(),
  name: p.nonEmptyString(),
  phone: p.nonEmptyString(),
  email: p.email(),
  isPrimary: z.boolean(),
  phoneExtension: p.nonEmptyString().nullable().optional(),
  cellphone: p.nonEmptyString().nullable().optional(),
  mailingAddress: zAddress,
})

export const zSummaryContact = z.object({
  id: p.nonEmptyString(),
  name: p.nonEmptyString(),
  email: p.nonEmptyString(),
  phone: p.phoneNumber().nullable(),
  contactType: p.nonEmptyString(),
})

export const zPrimaryContact = zContact.extend({
  isPrimary: z.literal(true),
})

export const zNonPrimaryContact = zContact.extend({
  isPrimary: z.literal(false),
  mailingAddress: zAddress.nullable().optional(),
})

export const zAnyContact = z.union([zPrimaryContact, zNonPrimaryContact])

export type SummaryContactType = z.infer<typeof zSummaryContact>
export type AnyContactType = z.infer<typeof zAnyContact>
