import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EditableContact } from '../../models/forms'

interface Props {
  primaryContact: EditableContact
  isOpen: boolean
  onClose(): void
  onChange(): void
}

export default function ChangePrimaryContactDialog(props: Props): JSX.Element {

  const { t } = useTranslation()

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="change-primary-contact-dialog-title"
      aria-describedby="change-primary-contact-dialog-description"
    >
      <DialogTitle id="change-primary-contact-dialog-title">
        {t('changingThePrimaryContactTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="change-primary-contact-dialog-description">
          {t('changingThePrimaryContactDescription', { contactName: props.primaryContact?.name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">{t('cancel')}</Button>
        <Button onClick={props.onChange} color="primary" autoFocus>{t('change')}</Button>
      </DialogActions>
    </Dialog>
  )
}
