import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/system/Stack'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import Footer from '../../components/Footer/Footer'
import Loader from '../../components/Loader'
import LoadingFetchError from '../../components/LoadingFetchError'
import { FileExtension, useThankYouQuery } from '../../generated/telecomGraphqlService'
import { ReactComponent as ThankYouSVG } from '../../svgs/thankYou.svg'
import AcceptedFor, { AcceptedForProps } from './sections/AcceptedFor'
import PaymentInformation, { PaymentInformationProps } from './sections/PaymentInformation'
import UploadedFiles from './sections/UploadedFiles'

export default function ThankYouPage(): JSX.Element {
  const { t } = useTranslation()

  const { data, refetch, isError, isLoading } = useThankYouQuery(
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  if (isError) {
    return <LoadingFetchError tryAgain={refetch} />
  }

  if (isLoading || !data?.getThankYou) {
    return <Loader loading={isLoading} />
  }

  const acceptedForProps: AcceptedForProps = {
    telecomUsername: data.getThankYou.accountNumber,
    telecomName: data.getThankYou.accountName,
    date: data.getThankYou.filingDate,
    time: data.getThankYou.filingTime,
  }

  const paymentInformationProps: PaymentInformationProps = {
    deadline: new Date(data.getThankYou.filingDeadline),
    interestRate: data.getThankYou.interestRate.toString(),
    appealDeadline: new Date('2024-07-30T00:00:00'), //TODO Change to endpoint value when get added to endpoint
    countyContactsURL: data?.getThankYou?.countyContactsURL as string,
    penaltyPercent: data?.getThankYou.penaltyPercent,
    paymentDeadline: new Date('2024-07-01T00:00:00'),

  }

  return (
    <>
      <Helmet>
        <title>
          {t('thankYou')} • {t('personalProperty')}
        </title>
      </Helmet>

      <Stack justifyContent="space-between" spacing={4} alignItems="center">
        <Typography component="h2" sx={{ typography: { xs: 'h4', md: 'h3' } }}>
          {t('thankYou')}
        </Typography>

        <Typography variant="h6" component="h3">
          {t('thankYouSubtitle', { taxYear: data.getThankYou.taxYear })}
        </Typography>

        <Box>
          <ThankYouSVG width="100%" height="auto" />
        </Box>

        <Stack columnGap={8} rowGap={4} direction={{ xs: 'column', md: 'row' }}>
          <AcceptedFor {...acceptedForProps} />
          <PaymentInformation {...paymentInformationProps} />
        </Stack>

        <Stack alignItems="center">
          <Typography variant="body2">{t('thankYouRetainCopy')}</Typography>
          <UploadedFiles
            leasedFile={data.getThankYou?.leaseFile as FileExtension}
            nonLeasedFile={data.getThankYou?.nonLeaseFile as FileExtension}
          />
        </Stack>
      </Stack>
      <Footer />
    </>
  )
}
