import { Theme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContactListItemText } from 'shared-components'

// import ContactListItemText from '../../components/ContactListItemText'
import { EditableContact } from '../../models/forms'

interface Props {
  contacts: EditableContact[]
  isOpen: boolean
  onClose(): void
  onRemove(selectedContact: EditableContact): void
}

export default function RemovePrimaryContactDialog(props: Props): JSX.Element {

  const classes = useStyles()

  const { t } = useTranslation()

  const [selectedContact, setSelectedContact] = React.useState<EditableContact>()

  const renderContact = (contact: EditableContact, index: number): React.ReactNode => {

    if (contact.isPrimary) return null

    const isSelected = selectedContact?.id === contact.id

    const onClick = (): void => setSelectedContact(contact)

    return (
      <ListItem
        key={contact.id}
        id={`${dialogListItemId}-${contact.id}`}
        selected={isSelected}
        // TODO: Posibly remove this argument
        // https://github.com/mui-org/material-ui/issues/26537
        aria-selected={isSelected}
        onClick={onClick}
        component="li"
        role="option"
        button
      >
        <ContactListItemText
          name={contact.name || t('contactNumber', { number: index + 1 })}
          contactType={contact.contactType?.label ?? null}
          isPrimary={contact.isPrimary}
        />
      </ListItem>
    )
  }

  const onChangePrimaryContact = (): void => {
    props.onRemove(selectedContact as EditableContact)
  }

  return (
    <Dialog
      id={dialogId}
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <DialogTitle id={dialogTitleId}>
        {t('removingThePrimaryContactTitle')}
      </DialogTitle>
      <DialogContentText id={dialogDescriptionId} classes={classes}>
        {t('removingThePrimaryContactDescription')}
      </DialogContentText>
      <DialogContent>
        <List
          id={dialogListId}
          role="listbox"
          tabIndex={0}
          aria-labelledby={dialogListItemId}
        >
          {props.contacts?.map(renderContact)}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">{t('cancel')}</Button>
        <Button
          onClick={onChangePrimaryContact}
          disabled={selectedContact === undefined}
          color="primary"
          autoFocus
        >
          {t('choose')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const dialogId = 'remove-primary-contact-dialog'
const dialogTitleId = `${dialogId}-title`
const dialogDescriptionId = `${dialogId}-description`
const dialogListId = `${dialogId}-list`
const dialogListItemId = `${dialogListId}-item`

const useStyles = makeStyles((theme: Theme) => ({
  root: { padding: theme.spacing(1, 4) },
}))
