import React from 'react'

import LoadingFetchError from '../../components/LoadingFetchError'
import { useGetAccountInformationQuery } from '../../generated/telecomGraphqlService'
import AccountInformationPage from './AccountInformationPage'
import AccountInformationSkeleton from './AccountInformationSkeleton'

export default function AccountInformation(): JSX.Element {
  const {
    data: accountInformationResponse,
    refetch: refetchGetAccountInformation,
    isError: hasAccountInformationError,
    isLoading: isGetAccountLoading,
  } = useGetAccountInformationQuery(
    {},
    { refetchOnWindowFocus: false, retry: true, refetchOnMount: true },
  )

  const isPageLoading = isGetAccountLoading
  const hasPageErrors = hasAccountInformationError

  const onRefetch = (): void => {
    if (hasAccountInformationError) {
      refetchGetAccountInformation()
    }
  }

  if (hasPageErrors || accountInformationResponse?.getAccount === null) {
    return <LoadingFetchError tryAgain={onRefetch} />
  }

  if (isPageLoading || !accountInformationResponse?.getAccount) {
    return <AccountInformationSkeleton />
  }

  const { getAccount: account, contactTypes } = accountInformationResponse

  return <AccountInformationPage account={account} contactTypes={contactTypes} />
}
