import * as React from 'react'
import * as Z from 'zod'

interface UseFormProps<Schema> {
  fields: Schema
  schema: Z.ZodSchema<Schema>
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, result: ValidationResponse<Schema>) => Promise<void>
}

interface UseFormReturnType<Schema> {
  fields: Schema
  setFields: React.Dispatch<React.SetStateAction<Schema>>
  fieldsErrors: Partial<Record<keyof Schema, string>>
  setFieldsErrors: React.Dispatch<React.SetStateAction<Partial<Record<keyof Schema, string>>>>,
  handleBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent) => void
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export default function useForm<Schema>(props: UseFormProps<Schema>): UseFormReturnType<Schema> {
  const [fields, setFields] = React.useState(props.fields)
  const [fieldsErrors, setFieldsErrors] = React.useState<ValidationErrors<Schema>>({})

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const result = await validateSchema<Schema>({
      schema: props.schema,
      formData: fields,
    })

    setFieldsErrors(result.errors || {})

    if (result.countErrors) {
      return
    }

    props.handleSubmit(event, result)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent): void => {
    if (!event?.target || !(event?.target as HTMLInputElement).name) {
      return
    }

    const { name, value } = event.target as HTMLInputElement

    setFieldsErrors({ ...fieldsErrors, [name]: '' })
    setFields({ ...fields, [name]: value })
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target

    setFields({ ...fields, [name]: checked })
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const name = event.target.name

    const key = (props.schema as Z.SomeZodObject).shape[name]
    const parsedValue = key.safeParse(fields[name as keyof Schema])

    if (!parsedValue.success) {
      setFieldsErrors({
        ...fieldsErrors,
        [name]: (parsedValue as Z.SafeParseError<Schema>).error.issues[0].message,
      })
    }
  }

  return {
    fields,
    setFields,
    fieldsErrors,
    setFieldsErrors,
    handleBlur,
    handleChange,
    handleCheckboxChange,
    handleSubmit,
  }
}

export interface ValidationSchemaOptions<Schema> {
  schema: Z.ZodSchema
  formData: Schema
}

export type ValidationErrors<T> = Partial<Record<keyof T, string>>

export interface ValidationResponse<Schema> {
  formData: Schema
  errors: ValidationErrors<Schema> | null
  countErrors: number
}

export async function validateSchema<Schema>(
  options: ValidationSchemaOptions<Schema>,
): Promise<ValidationResponse<Schema>> {
  const { schema, formData } = options
  try {
    return {
      formData: schema.parse(formData) as Schema,
      errors: null,
      countErrors: 0,
    } as ValidationResponse<Schema>
  } catch (e) {
    const errors = e as Z.ZodError<Schema>

    return {
      formData,
      errors: errors.issues.reduce((acc, error) => {
        const key = error.path[0]

        acc[key as keyof Schema] = error.message

        return acc
      }, {} as ValidationErrors<Schema>),
      countErrors: errors.issues.length,
    } as ValidationResponse<Schema>
  }
}
