import { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CsvSVG } from '../../svgs/csv.svg'
import { ReactComponent as PdfSVG } from '../../svgs/pdf.svg'
import { FileExt } from '../ThankYouPage/sections/FileTile'

interface Props {
  filename: string
  href: string
  extension: FileExt
  setExtension(ext: FileExt): void
}

export default function SummaryFile(props: Props): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()

  const onFileExtensionChange = (event: SelectChangeEvent<FileExt>): void => {
    props.setExtension(event.target.value as FileExt)
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      {props.extension === FileExt.pdf ? <PdfSVG /> : <CsvSVG />}
      <Box display="flex" flexDirection="column" width="100%">
        <Tooltip title={props.filename} placement="top">
          <Typography variant="body1" className={classes.textTruncate}>
            {props.filename}
          </Typography>
        </Tooltip>
      </Box>
      <Box pb={3} display="flex" flexDirection="column" alignItems="center" width="100%" minWidth={165}>
        <FormControl variant="filled" fullWidth className={classes.formControl}>
          <InputLabel id="file-extension-label">{t('format')}</InputLabel>
          <Select
            labelId="file-extension-label"
            value={props.extension}
            onChange={onFileExtensionChange}
            label={t('format')}
          >
            <MenuItem value={FileExt.pdf}>PDF</MenuItem>
            <MenuItem value={FileExt.csv}>CSV</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button variant="contained" href={props.href} download={props.filename}>
          {t('download')}
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    flex: 1,
    gap: theme.spacing(2),
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))
