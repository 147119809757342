import { Download } from '@mui/icons-material'
import { Button, Typography, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Csv } from '../../../svgs/csv.svg'
import { ReactComponent as Pdf } from '../../../svgs/pdf.svg'

export interface FileTileProps {
  filename: string
  href: string
  extension: FileExt
  setExtension(ext: FileExt): void
}

export enum FileExt {
  pdf = 'pdf',
  csv = 'csv',
}
export default function FileTile(props: FileTileProps): JSX.Element {
  const { t } = useTranslation()
  const { extension, setExtension } = props


  const onFileExtensionChange = (event:SelectChangeEvent<FileExt>): void => {
    setExtension(event.target.value as FileExt)
  }
  return (
    <Stack spacing={1} p={2} alignItems="center">
      {props.extension === FileExt.pdf ? <Pdf /> : <Csv />}
      <Typography variant="h6" component="h3">
        {props.filename}
      </Typography>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="file-extension-label">{t('format')}</InputLabel>
        <Select
          labelId="file-extension-label"
          value={extension}
          onChange={onFileExtensionChange}
          label={t('format')}
        >
          <MenuItem value={FileExt.pdf}>PDF</MenuItem>
          <MenuItem value={FileExt.csv}>CSV</MenuItem>
        </Select>
      </FormControl>
      <Button color="primary" download={props.filename} href={props.href} variant="contained">
        <Download /> {t('downloadFile')}
      </Button>
    </Stack>
  )
}
