import { Box, Theme } from '@mui/material'
import Alert from '@mui/material/Alert'
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContactField, EmailField, Panel, PasswordField, PhoneField, TextField } from 'shared-components'
import * as z from 'zod'
import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle/PageTitle'

import Stepper from '../../components/Stepper'
import { OathStatement, Summary, useSaveSummaryMutation } from '../../generated/telecomGraphqlService'
import useAuth from '../../hooks/useAuth'
import useForm, { ValidationResponse } from '../../hooks/useForm'
import { SummaryAndSignaturePayload } from '../../models/api'
import * as p from '../../utils/parsers'
import { goToThankYouPage } from '../../utils/routeActions'
import { SummaryContactType, zSummaryContact } from '../../utils/validations'

import OathDialog from './OathDialog'
import SummaryFilesTable from './SummaryFilesTable'
import SummaryTable from './SummaryTable'

const schema = z.object({
  pin: z.string().min(1),
  email: z.string().email(),
  phone: p.phoneNumber(),
  preparedAndSubmittedBy: zSummaryContact.nullable(),
  preparedAndSubmittedByInputValue: z.string().min(1),
  affirmOath: z.boolean(),
})

type SummaryAndSignatureSchema = z.TypeOf<typeof schema>

interface Props {
  summary: Summary
  oathStatement: OathStatement
}

export default function SummaryAndSignature(props: Props): JSX.Element {
  const { summary, oathStatement } = props
  const navigate = useNavigate()

  // Fields
  const { fields, setFields, fieldsErrors, setFieldsErrors, handleChange, handleBlur, handleSubmit } = useForm({
    schema,
    handleSubmit: customHandleSubmit,
    fields: {
      pin: '',
      email: '',
      phone: '',
      preparedAndSubmittedBy: null,
      preparedAndSubmittedByInputValue: '',
      affirmOath: false,
    },
  })

  const [oath, setOath] = React.useState(false)
  const { user } = useAuth()

  // UI Elements
  const [isFormLoading, setIsFormLoading] = React.useState(false)
  const [errorText, setErrorText] = React.useState<string | null>(null)
  const [isOathDialogOpen, setIsOathDialogOpen] = React.useState(false)

  const classes = useStyles()
  const { t } = useTranslation()

  const { mutateAsync: saveSummaryMutation } = useSaveSummaryMutation()

  async function customHandleSubmit(
    event: React.FormEvent<HTMLFormElement>,
    result: ValidationResponse<SummaryAndSignatureSchema>,
  ): Promise<void> {
    if (!oath) {
      return openOathDialog()
    }

    try {
      const payload: SummaryAndSignaturePayload = {
        email: result.formData.email,
        phone: result.formData.phone,
        pin: result.formData.pin,
        preparedAndSubmittedBy: result.formData.preparedAndSubmittedByInputValue,
      }

      setIsFormLoading(true)
      const name = result.formData.preparedAndSubmittedByInputValue
      const submittedBy = result.formData.preparedAndSubmittedBy

      const id = submittedBy?.name === name ? submittedBy.id : '0'

      const { pin, ...contact } = payload

      const summaryInput = {
        pin,
        accountNumber: user.accountNumber,
        contact: { id, ...contact },
      }

      const saveSummaryResponse = await saveSummaryMutation({ summaryInput: summaryInput })
      if (saveSummaryResponse?.saveSummary) {
        setIsFormLoading(false)
        goToThankYouPage(navigate)
      }
    } catch (e) {
      setErrorText(e instanceof Error ? e.message : t('oopsSomethingWentWrong'))
      setIsFormLoading(false)
    }
  }

  const closeErrorMessage = (): void => {
    setErrorText(null)
  }

  const onPreparedAndSubmittedByChange = (
    _: React.ChangeEvent<unknown>,
    value: SummaryContactType | null | string,
  ): void => {
    if (typeof value !== 'string' && value !== null) {
      const phone = value?.phone as string
      const newEmail = value?.email as string
      const newPreparedAndSubmittedBy = value?.name as string

      setFields({
        ...fields,
        email: newEmail,
        phone: phone,
        preparedAndSubmittedBy: value,
        preparedAndSubmittedByInputValue: newPreparedAndSubmittedBy,
      })

      setFieldsErrors({
        ...fieldsErrors,
        email: '',
        phone: '',
        preparedAndSubmittedBy: '',
        preparedAndSubmittedByInputValue: '',
      })
    }

    if (value === null) {
      setFields({
        ...fields,
        email: '',
        phone: '',
        preparedAndSubmittedBy: null,
        preparedAndSubmittedByInputValue: '',
      })
    }
  }

  const onPreparedAndSubmittedByInputChange = (_: React.ChangeEvent<unknown>, value: string): void => {
    setFields({ ...fields, preparedAndSubmittedByInputValue: value })
  }

  const openOathDialog = (): void => {
    setIsOathDialogOpen(true)
  }

  const onOathClick = (): void => {
    openOathDialog()
  }

  const onOathAffirmClick = (): void => {
    const oathNewValue = true

    setOath(oathNewValue)
  }

  const onOathDismissClick = (): void => {
    const oathNewValue = false

    setOath(oathNewValue)
  }

  const onOathDialogClose = (): void => {
    setIsOathDialogOpen(false)
  }

  const onChange = (_value: string, event: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(event)
  }

  return (
    <>
      <Stack spacing={3} component="form" onSubmit={handleSubmit}>
        <Stepper activeStep={3} />

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <PageTitle title={t('summaryAndSignature')} />
          <Box>
            <Button
              href={summary.countyContactsURL as string}
              download={summary.countyContactsURL}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              className={classes.countyButton}
              sx={{ marginRight: { sx: '7em', md: '4em', lg: '-1.5em' } }}
              disabled={!summary?.countyContactsURL}
            >
              {t('countyContacts')}
            </Button>
          </Box>
        </Stack>

        <ul>
          {[
            t('summaryAndSignatureInstruction1'),
            t('summaryAndSignatureInstruction2'),
            t('summaryAndSignatureInstruction3'),
            t('summaryAndSignatureInstruction4'),
            t('summaryAndSignatureInstruction5'),
          ].map((text) => (
            <li key={text}>
              <Typography>{text}</Typography>
            </li>
          ))}
        </ul>

        <Grid container spacing={3}>
          <Grid item xs={11} sm={11} md={5.5} lg={6}>
            <Grid container spacing={3} direction="column">
              <Grid item xs={12} sm={12} md={12} sx={{ width: '100%' }}>
                <Panel classes={{ root: classes.rootPanel }} headerText={t('summary')} headerTextComponent="h3">
                  {summary && <SummaryTable summary={summary} />}
                </Panel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{ width: '100%' }}>
                <Panel
                  classes={{ root: classes.rootPanel }}
                  headerText={t('filesAttached')}
                  headerTextComponent="h3"
                >
                  {summary && <SummaryFilesTable summary={summary} />}
                </Panel>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={11} sm={11} md={5.5} lg={6}>
            <Panel
              classes={{ root: classes.rootPanel }}
              headerText={t('electronicSubmissionSignatureAndAffirmation')}
              headerTextComponent="h3"
            >
              {isFormLoading && <LinearProgress />}
              <Grid container className={classes.panelContent} spacing={2}>
                <Grid item xs={12}>
                  <ContactField
                    options={summary.contacts as SummaryContactType[]}
                    value={fields.preparedAndSubmittedBy}
                    onChange={onPreparedAndSubmittedByChange}
                    inputValue={fields.preparedAndSubmittedByInputValue}
                    onInputChange={onPreparedAndSubmittedByInputChange}
                    renderInput={renderInput}
                    getContactName={getContactName}
                    getContactType={getContactType}
                    isPrimaryContact={isPrimaryContact}
                    isOptionEqualToValue={getOptionSelected}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={12}>
                  <EmailField
                    name="email"
                    label={t('email')}
                    inputProps={getInputProps(t('email'))}
                    value={fields.email}
                    onChange={onChange}
                    onBlur={handleBlur}
                    error={!!fieldsErrors.email}
                    helperText={fieldsErrors.email}
                    autoComplete="off"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <PhoneField
                    name="phone"
                    label={t('phone')}
                    inputProps={getInputProps(t('phone'))}
                    value={fields.phone}
                    onChange={onChange}
                    onBlur={handleBlur}
                    error={!!fieldsErrors.phone}
                    helperText={fieldsErrors.phone}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <PasswordField
                    name="pin"
                    label={t('pin')}
                    value={fields.pin}
                    onChange={onChange}
                    onBlur={handleBlur}
                    error={!!fieldsErrors.pin}
                    helperText={fieldsErrors.pin}
                    inputProps={getPinInputProps(t('pin'))}
                    autoComplete="new-password"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    label={t('affirmOath')}
                    onClick={onOathClick}
                    control={<Checkbox inputProps={getInputProps(t('affirmOath'))} checked={oath} />}
                  />
                </Grid>
              </Grid>
            </Panel>
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={12}>
            <div className={classes.confirmButtonWrapper}>
              <Button variant="contained" color="primary" type="submit">
                {t('submitDeclaration')}
              </Button>
            </div>
          </Grid>
        </Grid>

        {errorText && (
          <Snackbar open={true} onClose={closeErrorMessage}>
            <Alert severity="error" variant="filled" onClose={closeErrorMessage}>
              {errorText}
            </Alert>
          </Snackbar>
        )}

        {oathStatement && (
          <OathDialog
            isOpen={isOathDialogOpen}
            oathStatement={oathStatement}
            onAffirm={onOathAffirmClick}
            onDismiss={onOathDismissClick}
            onClose={onOathDialogClose}
          />
        )}
      </Stack>
      <Footer />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  rootPanel: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  panelContent: {
    padding: theme.spacing(3, 3, 0, 3),
    marginBottom: theme.spacing(3),
  },
  confirmButtonWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    textAlign: 'right',
  },
  comments: {
    width: '100%',
    padding: theme.spacing(1.5),
    resize: 'vertical',
  },
  timeToCompleteWrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
  countyButton: {
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(6),
    },
  },
}))

function getPinInputProps(ariaLabel: string): InputBaseComponentProps {
  return {
    // Maximum allowed are 50 characters
    maxLength: 50,
    'aria-label': ariaLabel,
  }
}

function getInputProps(ariaLabel: string): InputBaseComponentProps {
  return { 'aria-label': ariaLabel }
}

function renderInput(params: AutocompleteRenderInputParams): React.ReactNode {
  return <TextField {...params} label={<Trans i18nKey="preparedAndSubmittedBy" />} variant="standard" required />
}

function getContactName(contact: SummaryContactType): string {
  return typeof contact === 'string' ? contact : contact?.name
}

function getContactType(contact: SummaryContactType): string {
  return contact?.contactType as string
}

function isPrimaryContact(contact: SummaryContactType | null): boolean {
  return contact?.contactType === 'Primary'
}

function getOptionSelected(option: SummaryContactType, value: SummaryContactType): boolean {
  return getContactName(option) === getContactName(value)
}
