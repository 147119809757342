/**
 * Parsers module
 *
 * Define parsers for the different concepts in our application.
 * @module
 */

import { z } from 'zod'

import { State } from '../generated/telecomGraphqlService'

export function email(): z.ZodString {
  return nonEmptyString().email()
}

/**
 * @see {@link https://www.irs.gov/pub/irs-pdf/p1635.pdf Employer Identification Number}
 */
export function employerIdentificationNumber(): z.ZodEffects<z.ZodString> {
  return z.string().refine((s) => /\d{2}-\d{7}/.test(s))
}

export function nonEmptyString(): z.ZodString {
  return z.string().min(1)
}

/**
 * @see {@link https://en.wikipedia.org/wiki/North_American_Numbering_Plan#Modern_plan North American Numbering Plan: Modern plan}
 */
export function phoneNumber(): z.ZodEffects<z.ZodString> {
  return z.string().refine((s) => /\([2-9]\d{2}\) [2-9]\d{2}-\d{4}/.test(s))
}

export function positiveNumber(): z.ZodNumber {
  return z.number().positive()
}

/**
 * @see {@link https://pe.usps.com/text/pub28/28apb.htm Postal Addressing Standards - Appendix B}
 */
export function state(): z.ZodNativeEnum<typeof State> {
  return z.nativeEnum(State)
}

export function url(): z.ZodString {
  return nonEmptyString().url()
}

/**
 * Does this value conform to the ISO 8601 standard for what is a year?
 * @see {@link https://en.wikipedia.org/wiki/ISO_8601#Years ISO 8601: Years}
 */
export function year(): z.ZodNumber {
  return z.number().int().gte(1583)
}

/**
 * @see {@link https://pe.usps.com/archive/html/dmmarchive20030810/A010.htm Standard Addressing Formats}
 */
export function zipCode(): z.ZodUnion<[z.ZodEffects<z.ZodString>, z.ZodEffects<z.ZodString>]> {
  return z.union([z.string().refine((s) => /\d{5}/.test(s)), z.string().refine((s) => /\d{5}-\d{4}/.test(s))])
}
