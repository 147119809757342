import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function DragAndDropMessage(): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Typography
        className={classes.noPointer}
        component="h4"
        sx={{ typography: { xs: 'h5', sm: 'h5', md: 'h4' } }}
      >
        {t('dragAndDropToUploadFileOr')}
      </Typography>

      <Button className={classes.noPointer} variant="outlined" startIcon={<AttachFileRoundedIcon />}>
        {t('importCSV')}
      </Button>
    </>
  )
}

const useStyles = makeStyles({
  noPointer: {
    pointerEvents: 'none',
  },
})
