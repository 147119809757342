import { Theme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EditableContact } from '../../models/forms'

interface Props {
  currentContact: EditableContact
  isOpen: boolean
  onClose(): void
  onRemove(): void
}

export default function RemoveNonPrimaryContactDialog(props: Props): JSX.Element {

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Dialog
      id={dialogId}
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <DialogTitle id={dialogTitleId}>
        {t('removingNonPrimaryContactTitle')}
      </DialogTitle>
      <DialogContentText id={dialogDescriptionId} classes={classes}>
        {t('removingNonPrimaryContactDescription')}
      </DialogContentText>
      <DialogActions>
        <Button onClick={props.onClose}>{t('cancel')}</Button>
        <Button
          onClick={props.onRemove}
          color="secondary"
          autoFocus
        >
          {t('remove')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const dialogId = 'remove-non-primary-contact-dialog'
const dialogTitleId = `${dialogId}-title`
const dialogDescriptionId = `${dialogId}-description`

const useStyles = makeStyles((theme: Theme) => ({
  root: { padding: theme.spacing(1, 4) },
}))
