import { Theme } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import BasicAccountInformation from './BasicAccountInformation'
import ContactInformation from './ContactInformation'
import Wrapper from './Wrapper'

export default function AccountInformationSkeleton(): JSX.Element {

  const classes = useStyles()

  return (
    <Wrapper
      isSubmitDisabled
      leftPanel={
        <BasicAccountInformation
          yearOpenedField={<Skeleton height={70} className={classes.marginBottom} />}
          businessDescriptionField={<Skeleton height={70} className={classes.marginBottom} />}
          registeredEntityNameField={<Skeleton height={70} className={classes.marginBottom} />}
          employerNotificationNumberField={<Skeleton height={70} className={classes.marginBottom} />}
        />
      }
      rightPanel={
        <ContactInformation
          topContactSection={<Skeleton height={42} className={classes.marginBottom} />}
          contactTypeField={<Skeleton height={64} />}
          nameField={<Skeleton height={70} className={classes.marginBottom} />}
          emailField={<Skeleton height={70} className={classes.marginBottom} />}
          phoneField={<Skeleton height={48} className={classes.marginBottom} />}
          phoneExtensionField={<Skeleton height={70} className={classes.marginBottom} />}
          cellphoneField={<Skeleton height={70} />}
          mailingAddressHeader={<Skeleton height={42} />}
          inCareOfField={<Skeleton height={70} className={classes.marginBottom} />}
          addressLine1Field={<Skeleton height={70} className={classes.marginBottom} />}
          addressLine2Field={<Skeleton height={70} className={classes.marginBottom} />}
          cityField={<Skeleton height={70} className={classes.leftTextField} />}
          stateField={<Skeleton height={70} className={classes.leftTextField} />}
          zipCodeField={<Skeleton height={70} className={classes.zipCodeField} />}
        />
      }
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  leftTextField: {
    marginRight: theme.spacing(2),
    flex: 2,
  },
  zipCodeField: { flex: 1 },
}))
