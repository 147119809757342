import { isEinValid } from 'shared-components/material/EinField'
import { isEmailValid } from 'shared-components/material/EmailField'
import { isPhoneValid } from 'shared-components/material/PhoneField'
import { isZipCodeValid } from 'shared-components/material/ZipCodeField'

import { Account, Address, AddressOtherContact, Contact, ContactType, Maybe, NonPrimaryContact, PrimaryContact, State } from '../generated/telecomGraphqlService'
import { RequiredAll } from '../utils'
import { ValidAccount } from './api'
import History from './history'

interface AccountValidation {
  account: Validation<ValidAccount>
  contacts: Validation<Contacts, number[]>
}

type OmittedAddressKey = 'state'

export interface EditableAddress extends Omit<RequiredAll<Address>, OmittedAddressKey> {
  state: State | null
}

type OmittedContactKey =
  | 'contactType'
  | 'mailingAddress'

export interface EditableContact extends Omit<RequiredAll<Contact>, OmittedContactKey> {
  isPrimary: boolean
  contactType: Maybe<ContactType>
  sameAsBusinessAddress: boolean
  mailingAddress: EditableAddress
}

type OmittedAccountKey = 'yearOpened' | 'registeredEntityType' | 'primaryContact'

export interface EditableAccount extends Omit<RequiredAll<Account>, OmittedAccountKey> {
  yearOpened: Date | null
  contacts: History<EditableContact>
}

export function createEditableAddress(address: Address | AddressOtherContact): EditableAddress {
  return {
    inCareOf: address.inCareOf ?? '',
    addressLine1: address.addressLine1 ?? '',
    addressLine2: address.addressLine2 ?? '',
    city: address.city ?? '',
    state: address.state ?? null,
    zipCode: address.zipCode ?? '',
  }
}

const emptyContactsValidation: Validation<Contacts, number[]> = { valid: true, result: { contacts: [] } }

export function createNewEditableContact(isPrimary = false): EditableContact {
  return {
    isPrimary,
    id: Math.random().toString(),
    name: '',
    contactType: null,
    email: '',
    phone: '',
    phoneExtension: '',
    cellphone: '',
    sameAsBusinessAddress: false,
    mailingAddress: createNewEditableAddress(),
  }
}

function createNewEditableAddress(): EditableAddress {
  return {
    inCareOf: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: null,
    zipCode: '',
  }
}

export function validateAccountInformationForm(editable: EditableAccount): AccountValidation {
  const {
    businessDescription,
    registeredEntityName,
    yearOpened,
    employerIdentificationNumber,
    contacts,
    ...restEditable
  } = editable

  const yearOpenedValidation = validateYearOpened(yearOpened)
  const contactsValidation = contacts.array.reduce(reduceValidateContact, emptyContactsValidation)

  if (
    !yearOpenedValidation.valid ||
    !contactsValidation.valid ||
    !contactsValidation.result.primaryContact ||
    (employerIdentificationNumber && !isEinValid(employerIdentificationNumber))
  ) {
    return {
      account: { valid: false },
      contacts: contactsValidation,
    }
  }

  const account: ValidAccount = {
    ...restEditable,
    businessDescription: businessDescription || null,
    registeredEntityName: registeredEntityName || null,
    yearOpened: yearOpenedValidation.result,
    employerIdentificationNumber: employerIdentificationNumber || null,
    primaryContact: contactsValidation.result.primaryContact,
    otherContacts: contactsValidation.result.contacts,
  }

  return {
    account: { valid: true, result: account },
    contacts: contactsValidation,
  }
}

function reduceValidateContact(
  checked: Validation<Contacts, number[]>,
  editable: EditableContact,
  index: number,
): Validation<Contacts, number[]> {
  const { id, name, isPrimary, email, phone, phoneExtension, cellphone, mailingAddress, contactType } = editable

  const addressValidation = validateAddress(isPrimary, mailingAddress)

  if (
    !addressValidation.valid ||
    !name ||
    !email ||
    !contactType ||
    !isEmailValid(email) ||
    !isPhoneValid(phone) ||
    (cellphone && !isPhoneValid(cellphone))
  ) {
    return addErrorIndex(checked, index)
  }

  // TODO: Make a distinction between a new and an existing Contact
  // Their id right now is a float and not an integer
  // https://mcatcama.visualstudio.com/Puma/_workitems/edit/5393
  const contact: Omit<Contact, 'isPrimary' | 'mailingAddress'> = {
    id: Math.floor(Number(id)).toString(),
    name,
    email,
    phone,
    contactType: contactType as ContactType,
    phoneExtension: phoneExtension || null,
    cellphone: cellphone || null,
  }


  if (isPrimary) {

    if (!addressValidation.result) {
      return addErrorIndex(checked, index)
    }

    if (!checked.valid) {
      return checked
    }

    const primaryContact: PrimaryContact = {
      ...contact,
      mailingAddress: addressValidation.result as Address,
    }

    return { ...checked, result: { ...checked.result, primaryContact } }
  }

  if (!checked.valid) {
    return checked
  }

  const nonPrimaryContact: NonPrimaryContact = {
    ...contact,
    mailingAddress: addressValidation.result as AddressOtherContact,
  }

  return {
    ...checked,
    result: {
      ...checked.result,
      contacts: [...checked.result.contacts, nonPrimaryContact],
    },
  }
}

function validateYearOpened(yearOpenedDate: EditableAccount['yearOpened']): Validation<number | null> {
  const yearOpened = yearOpenedDate?.getFullYear()
  const currentYear = new Date().getFullYear()

  if (yearOpenedDate === null) {
    return { valid: true, result: null }
  }

  return typeof yearOpened === 'number' && !Number.isNaN(yearOpened) && yearOpened >= 1583 && yearOpened <= currentYear
    ? { valid: true, result: yearOpened }
    : { valid: false }
}

// Form Validation

type Validation<T, InvalidMetadata = undefined> = ValidationSuccess<T> | ValidationFail<InvalidMetadata>

type ValidationSuccess<T> = { valid: true; result: T }
type ValidationFail<InvalidMetadata = undefined> = InvalidMetadata extends undefined
  ? { valid: false }
  : { valid: false; metadata: InvalidMetadata }

export type MappedError<T, E = boolean> = {
  [field in keyof T]?: E
}

interface Contacts {
  primaryContact?: PrimaryContact
  contacts: NonPrimaryContact[]
}

function addErrorIndex<T>(checked: Validation<T, number[]>, index: number): Validation<T, number[]> {
  return checked.valid
    ? { valid: false, metadata: [index] }
    : { ...checked, metadata: [...checked.metadata, index] }
}

export function createEditableAccount(account: Account): EditableAccount {
  const {
    businessDescription,
    registeredEntityName,
    employerIdentificationNumber,
    primaryContact,
    otherContacts,
    ...restAccount
  } = account

  const yearOpened = new Date()
  if (account.yearOpened) yearOpened.setFullYear(account.yearOpened)

  return {
    ...restAccount,
    yearOpened: account.yearOpened ? yearOpened : null,
    businessDescription: businessDescription ?? '',
    registeredEntityName: registeredEntityName ?? '',
    employerIdentificationNumber: employerIdentificationNumber ?? '',
    contacts: History.init(
      createPrimaryEditableContact(primaryContact),
      otherContacts.map(createEditableContact.bind(null, false)),
    ),
  } as EditableAccount
}

function createPrimaryEditableContact(contact?: Contact | null): EditableContact {
  return contact ? createEditableContact(true, contact) : createNewEditableContact()
}

function createEditableContact(isPrimary = false, contact: NonPrimaryContact): EditableContact {
  return {
    isPrimary,
    id: contact.id,
    name: contact.name ?? '',
    email: contact.email ?? '',
    phone: contact.phone ?? '',
    phoneExtension: contact.phoneExtension ?? '',
    cellphone: contact.cellphone ?? '',
    contactType: contact.contactType,
    mailingAddress: contact.mailingAddress
      ? createEditableAddress(contact.mailingAddress)
      : createNewEditableAddress(),
  } as EditableContact
}

function validateAddress(isPrimary: boolean, editable?: EditableAddress): Validation<Address | AddressOtherContact | null, MappedError<EditableAddress>> {
  if (!editable) {
    return { valid: true, result: null }
  }

  const { inCareOf, addressLine1, addressLine2, city, state, zipCode } = editable

  // non-primary contacts
  if (!isPrimary) {
    if (zipCode && !isZipCodeValid(zipCode)) {
      return {
        valid: false,
        metadata: {
          zipCode: !isZipCodeValid(zipCode),
        },
      }
    }

    return {
      valid: true,
      result: {
        addressLine1: addressLine1 || null,
        city: city || null,
        zipCode: zipCode || null,
        state: state || null,
        inCareOf: inCareOf || null,
        addressLine2: addressLine2 || null,
      } as AddressOtherContact,
    }
  }


  if (!addressLine1 && !addressLine2 && !city && !state && !zipCode) {
    return { valid: true, result: null }
  }

  if (!addressLine1 || !city || !state || !isZipCodeValid(zipCode)) {
    return {
      valid: false,
      metadata: {
        addressLine1: !addressLine1,
        city: !city,
        state: !state,
        zipCode: !isZipCodeValid(zipCode),
      },
    }
  }

  return {
    valid: true,
    result: {
      addressLine1,
      city,
      state,
      zipCode,
      inCareOf: inCareOf || null,
      addressLine2: addressLine2 || null,
    } as Address,
  }
}

export function isPrimaryContact(contact: EditableContact): boolean {
  return contact.isPrimary
}
