import React from 'react'
import { Routes, Route } from 'react-router-dom'

// Pages
import AccountInformationPage from '../../pages/AccountInformationPage'
import ForgotPin from '../../pages/ForgotPin'
import LeasedAssetListPage from '../../pages/LeasedAssetListPage'
import Login from '../../pages/Login'
import LoginRedirector from '../../pages/Login/LoginRedirector'
import PageNotFound from '../../pages/PageNotFound'
import PropertyAssetListPage from '../../pages/PropertyAssetListPage'
import StepRedirector from '../../pages/StepRedirector'
import SummaryAndSignaturePage from '../../pages/SummaryAndSignaturePage'
import ThankYouPage from '../../pages/ThankYouPage'

// Miscellaneous
import paths from '../../utils/paths'

// Components
import Layout from '../Layout'
import ProtectedRoute from '../ProtectedRoute'

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={paths.index} element={<StepRedirector />} />
        <Route element={<LoginRedirector />}>
          <Route path={paths.login} element={<Login />} />
          <Route path={paths.forgotPin} element={<ForgotPin />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path={paths.telecomAssetsFilling}>
            <Route
              path={paths.accountInformation}
              element={<AccountInformationPage />}
            />
            <Route
              path={paths.propertyAssetList}
              element={<PropertyAssetListPage />}
            />
            <Route
              path={paths.leasedAssetList}
              element={<LeasedAssetListPage />}
            />
            <Route
              path={paths.summaryAndSignature}
              element={<SummaryAndSignaturePage />}
            />
          </Route>
          <Route
            path={paths.thankYou}
            element={<ThankYouPage />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />}/>
      </Route>
    </Routes>
  )
}
