import { Paper, Stack } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PasswordField, TextField } from 'shared-components'
import Loader from '../../components/Loader'

import { LoginMutation, useLoginMutation } from '../../generated/telecomGraphqlService'
import useAuth from '../../hooks/useAuth'
import paths from '../../utils/paths'
import InformationModals from './InformationModals'
import PostLogin from './PostLogin'

export default function Login(): JSX.Element {
  const { authInformation, storeToken } = useAuth()

  const classes = useStyles()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [accountNumber, setAccountNumber] = useState('')
  const [pin, setPin] = useState('')
  const [hasError, setHasError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [jwt, setJwt] = useState('')

  useEffect(() => {
    if (authInformation) {
      setJwt(authInformation.token)
    }
  }, [authInformation])

  const login = useLoginMutation({
    onSuccess(data: LoginMutation) {
      const token = data?.login?.token

      if (token) {
        storeToken?.(token)
      } else {
        setError(t('failedToLogin'))
      }
    },
  })

  if (jwt) {
    return <PostLogin />
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (accountNumber.length && pin.length) {
      setIsSubmitDisabled(true)
      setLoading(true)
      try {
        await login.mutateAsync({
          accountNumber: accountNumber,
          pin: pin,
        })
      } catch (e) {
        const errorMessage = t('failedToLoginWithError', {
          error: ((e as Error)?.message as string) === '' ? 'Unknown error' : ((e as Error)?.message as string),
        })
        setError(errorMessage)
      }
    }
  }

  const setError = (message: string): void => {
    setHasError(true)
    setErrorText(message)
    setLoading(false)
    setIsSubmitDisabled(false)
  }

  const onCloseError = (): void => setHasError(false)

  const onForgotPin = (): void => {
    navigate(paths.forgotPin)
  }

  return (
    <>
      <Helmet>
        <title>
          {t('signIn')} • {t('personalProperty')}
        </title>
      </Helmet>

      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Typography variant="h5" width={'70%'} textAlign="center" style={{ wordWrap: 'break-word'}}>
          {t('loginTitle')}
        </Typography>
        <Paper className={classes.mainPaper} component="form" onSubmit={onSubmit}>
          <Stack spacing={4} p={4} alignItems="center" width={'90%'}>
            <Typography variant="h5" component="h2">
              {t('signIn')}
            </Typography>

            <TextField
              id="accountNumber"
              label={t('accountNumber')}
              onChange={setAccountNumber}
              inputProps={inputProps}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <PasswordField
              id="pin"
              label={t('pin')}
              onChange={setPin}
              inputProps={inputProps}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitDisabled}
              className={classes.loginButton}
              sx={{ my: '20px' }}
            >
              {t('signIn')}
            </Button>

            <Button
              variant="text"
              color="primary"
              type="button"
              sx={{ my: '20px', alignSelf: 'flex-start', color: 'black', fontWeight: '700' }}
              onClick={onForgotPin}
            >
              {t('forgotPin')}
            </Button>

            <Snackbar open={hasError} onClose={onCloseError}>
              <Alert severity="error" variant="filled" onClose={onCloseError}>
                {errorText}
              </Alert>
            </Snackbar>
          </Stack>
          <Loader loading={loading} />
        </Paper>

        <InformationModals />
      </Stack>
    </>
  )
}

const inputProps: InputBaseComponentProps = {
  maxLength: 100,
}

const useStyles = makeStyles({
  loginButton: { width: '100%' },
  mainPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
    minWidth: '18rem',
    maxWidth: '29rem',
  },
})
