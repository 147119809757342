import { Alert, Paper, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EmailField, PhoneField, TextField, isEmailValid, isPhoneValid } from 'shared-components'
import { GlobalSnackbarProps } from '../../components/GlobalSnackbar'
import Loader from '../../components/Loader'
import { usePinResetMutation } from '../../generated/telecomGraphqlService'

import useAppContext from '../../hooks/useAppContext'
import paths from '../../utils/paths'

type PinResetRequest = {
  accountNumber: string
  telecomName: string
  contactName: string
  email: string
  phone: string
}

export default function ForgotPin(): JSX.Element {
  const classes = useStyles()

  const { setGlobalSnackbarProps } = useAppContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [hasError, setHasError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [loading, setLoading] = useState(false)
  const [pinResetRequest, setPinResetRequest] = useState<PinResetRequest>({
    accountNumber: '',
    telecomName: '',
    contactName: '',
    email: '',
    phone: '',
  })

  const forgotPin = usePinResetMutation()

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!isEmailValid(pinResetRequest.email) || !isPhoneValid(pinResetRequest.phone)) {
      setError(t('formErrors'))
      return
    }
    setLoading(true)

    try {
      await forgotPin.mutateAsync({
        pinResetRequestInput: {
          telecomName: pinResetRequest.telecomName,
          telecomUsername: pinResetRequest.accountNumber,
          contact: {
            name: pinResetRequest.contactName,
            email: pinResetRequest.email,
            phone: pinResetRequest.phone,
          },
        },
      })

      setGlobalSnackbarProps?.({
        message: t('pinResetRequestWasCreated'),
      } as GlobalSnackbarProps)
      navigate(paths.login)
    } catch (e) {
      const errorMessage = t('failedToRequestPinResetWithError', {
        error: ((e as Error)?.message as string) === '' ? 'Unknown error' : ((e as Error)?.message as string),
      })
      setError(errorMessage)
    }
  }

  const handleChange = (value: string, e: React.ChangeEvent<HTMLInputElement>): void => {
    setPinResetRequest((previousState) => ({
      ...previousState,
      [e.target.id]: value,
    }))
  }

  const setError = (message: string): void => {
    setHasError(true)
    setErrorText(message)
    setLoading(false)
  }

  const onCloseError = (): void => setHasError(false)

  return (
    <>
      <Helmet>
        <title>
          {t('forgotPin')} • {t('personalProperty')}
        </title>
      </Helmet>

      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Paper className={classes.mainPaper} component="form" onSubmit={onSubmit}>
          <Stack spacing={4} p={4} alignItems="center" width={'90%'}>
            <Typography variant="h5" component="h2">
              {t('pinResetRequestTitle')}
            </Typography>
            <Typography variant="body1">{t('pinResetRequestText')}</Typography>
            <TextField
              id="accountNumber"
              label={t('accountNumber')}
              value={pinResetRequest.accountNumber}
              onChange={handleChange}
              inputProps={inputProps}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <TextField
              id="telecomName"
              label={t('telecomName')}
              value={pinResetRequest.telecomName}
              onChange={handleChange}
              inputProps={inputProps}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <TextField
              id="contactName"
              label={t('contactName')}
              value={pinResetRequest.contactName}
              onChange={handleChange}
              inputProps={inputProps}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <EmailField
              id="email"
              label={t('email')}
              value={pinResetRequest.email}
              onChange={handleChange}
              inputProps={inputProps}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />
            <PhoneField
              id="phone"
              label={t('phone')}
              value={pinResetRequest.phone}
              onChange={handleChange}
              inputProps={inputProps}
              margin="normal"
              variant="standard"
              fullWidth
              required
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              className={classes.submitButton}
              sx={{ my: '20px' }}
            >
              {t('submitPinResetRequest')}
            </Button>
            <Snackbar open={hasError} onClose={onCloseError}>
              <Alert severity="error" variant="filled" onClose={onCloseError}>
                {errorText}
              </Alert>
            </Snackbar>
          </Stack>
          <Loader loading={loading} />
        </Paper>
      </Stack>
    </>
  )
}

const inputProps: InputBaseComponentProps = {
  maxLength: 100,
}

const useStyles = makeStyles({
  submitButton: { width: '100%' },
  mainPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
    minWidth: '18rem',
    maxWidth: '29rem',
  },
})
