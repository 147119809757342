import MapIcon from '@mui/icons-material/Map'
import { Box, Button, Stack } from '@mui/material'
import Alert, { AlertColor } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import DragableFileUpload from '../../components/DragableFileUpload'
import Footer from '../../components/Footer/Footer'
import { GlobalSnackbarProps } from '../../components/GlobalSnackbar'
import PageTitle from '../../components/PageTitle/PageTitle'
import Stepper from '../../components/Stepper'
import {
  useDismissLeaseAssetListMutation,
  useGeneratePresignedUploadCsvFileMutation,
  useGetLeaseAssetListQuery,
  useSaveLeaseAssetListMutation,
  useSkipLeaseAssetListMutation,
} from '../../generated/telecomGraphqlService'
import useAppContext from '../../hooks/useAppContext'
import useAuth from '../../hooks/useAuth'
import { putFileInAzureStorage } from '../../utils/api'
import { goToSummaryAndSignature } from '../../utils/routeActions'
import theme from '../../utils/theme'

interface snackbarOptions {
  type: AlertColor
  message: string
}

export default function LeasedAssetListPage(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()
  const { setGlobalSnackbarProps } = useAppContext()
  const [validationReport, setValidationReport] = React.useState<string | null>(null)
  const [previousFileURL, setPreviousFileURL] = React.useState<string | null>(null)
  const [previousFileName, setPreviousFileName] = React.useState<string | null>(null)
  const [validationReportName, setValidationReportName] = React.useState<string | null>(null)
  const [snackbarOptions, setSnackbarOptions] = React.useState<snackbarOptions | null>(null)
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false)
  const [hasFormErrors, setHasFormErrors] = React.useState(false)
  const { user, authInformation } = useAuth()
  const { data, isLoading: getLeaseAssetListIsLoading } = useGetLeaseAssetListQuery(
    { accountNumber: user.accountNumber },
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: true,
      onSuccess(response) {
        if (response.getLeaseAssetList?.validationReport) {
          setValidationReport(response.getLeaseAssetList?.validationReport)
          setValidationReportName(response.getLeaseAssetList?.validationReportName as string)
        }
      },
    },
  )

  const { mutateAsync: generatePresignedToken } = useGeneratePresignedUploadCsvFileMutation()
  const { mutateAsync: saveLeaseAssetListMutation } = useSaveLeaseAssetListMutation()
  const { mutateAsync: skipLeaseAssetListMutation } = useSkipLeaseAssetListMutation()
  const { mutateAsync: dismissLeaseAssetListMutation } = useDismissLeaseAssetListMutation()

  const onSubmit = async (file: File | string): Promise<void> => {
    try {
      if (typeof file === 'string') {
        goToSummaryAndSignature(navigate)
        return
      }
      setIsFormSubmitting(true)

      const { generatePresignedUploadCsvFile } = await generatePresignedToken({
        isLeased: true,
      })

      await putFileInAzureStorage(generatePresignedUploadCsvFile?.url as string, file)

      const { saveLeaseAssetList } = await saveLeaseAssetListMutation({
        accountNumber: user.accountNumber,
        fileName: generatePresignedUploadCsvFile?.fileName as string,
        userId: String(authInformation.userId),
      })

      const fileName = generatePresignedUploadCsvFile?.fileName as string

      if (saveLeaseAssetList?.fileName) {
        setPreviousFileURL(saveLeaseAssetList?.fileName)
        setPreviousFileName(fileName)
      }

      if (saveLeaseAssetList?.validationReport) {
        setValidationReport(saveLeaseAssetList.validationReport)
        setPreviousFileURL(saveLeaseAssetList.fileName)
        setValidationReportName(fileName.replace('.csv', '_Error.pdf'))
        setIsFormSubmitting(false)

        return
      }

      setGlobalSnackbarProps?.({
        message: t('itemSaved', { item: t('leasedAssetList') }),
      } as GlobalSnackbarProps)

      goToSummaryAndSignature(navigate)
    } catch (error: unknown) {
      setIsFormSubmitting(false)
      setHasFormErrors(true)
      setSnackbarOptions({
        type: 'error',
        message: error instanceof Error ? error.message : t('oopsSomethingWentWrong'),
      })
    }
  }

  const onSkipLeasedAssetList = async (): Promise<void> => {
    try {
      const skipLeaseAssetListMutationResult = await skipLeaseAssetListMutation({
        accountNumber: user.accountNumber,
      })
      if (skipLeaseAssetListMutationResult) {
        goToSummaryAndSignature(navigate)
      }
    } catch (error: unknown) {
      setSnackbarOptions({
        type: 'error',
        message: error instanceof Error ? error.message : t('oopsSomethingWentWrong'),
      })
    }
  }

  const onDismissLeaseAssetList = async (): Promise<void> => {
    try {
      const dismissLeaseAssetListMutationResult = await dismissLeaseAssetListMutation({
        accountNumber: user.accountNumber,
      })

      if (dismissLeaseAssetListMutationResult.dismissLeaseAssetList) {
        setSnackbarOptions({
          type: 'success',
          message: t('itemDismiss'),
        })
        setPreviousFileURL('')
        removeValidationReport()
      }
    } catch (error: unknown) {
      setSnackbarOptions({
        type: 'error',
        message: error instanceof Error ? error.message : t('oopsSomethingWentWrong'),
      })
    }
  }

  const removeValidationReport = (): void => {
    setValidationReport(null)
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOptions(null)
  }

  return (
    <>
      <Stepper activeStep={2} />
      <Box component="header">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <PageTitle title={t('leasedAssetList')} />

          <Box className={classes.container}>
            <Button
              href="https://opendata.gis.utah.gov/datasets/utah::utah-tax-areas-2021/explore?location=39.472886%2C-111.547240%2C-1.00"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<MapIcon />}
              variant="outlined"
              className="btn"
            >
              {t('taxAreaMap')}
            </Button>

            <Button
              href={data?.getLeaseAssetList?.countyContactsURL as string}
              download={data?.getLeaseAssetList?.countyContactsURL}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              disabled={!data?.getLeaseAssetList?.countyContactsURL}
              className="btn"
            >
              {t('countyContacts')}
            </Button>
          </Box>
        </Stack>
      </Box>

      <ul>
        <li>
          <Typography>{t('leasedAssetListInstructions1')}</Typography>
          <ul>
            <li>
              <Typography><b>{t('leasedAssetListInstructionsA')}</b></Typography>
            </li>
            <li>
              <Typography>{t('leasedAssetListInstructionsB')}</Typography>
            </li>
            <li>
              <Typography>{t('leasedAssetListInstructionsC')}</Typography>
            </li>
            <li>
              <Typography>{t('leasedAssetListInstructionsD')}</Typography>
            </li>
          </ul>
        </li>
        <li>
          <Typography>{t('leasedAssetListInstructions2')}</Typography>
        </li>
      </ul>
      <DragableFileUpload
        downloadTemplateURL={data?.getLeaseAssetList?.templateURL}
        onSubmit={onSubmit}
        removeValidationReport={removeValidationReport}
        shouldShowSkipButton={true}
        handleSkip={onSkipLeasedAssetList}
        previousFileURL={previousFileURL ?? data?.getLeaseAssetList?.fileUrl}
        isLoading={getLeaseAssetListIsLoading}
        isSubmitting={isFormSubmitting}
        validationReport={validationReport}
        validationReportName={validationReportName}
        fileIconName={previousFileName ?? data?.getLeaseAssetList?.fileName as string}
        onDismiss={onDismissLeaseAssetList}
        navigateToNextStep={goToSummaryAndSignature}
        isLeased={true}
        hasErrors={hasFormErrors}
      />

      <Snackbar autoHideDuration={5000} open={!!snackbarOptions} onClose={handleCloseSnackbar}>
        <Alert severity={snackbarOptions?.type} onClose={handleCloseSnackbar}>
          {snackbarOptions?.message}
        </Alert>
      </Snackbar>
      <Footer />
    </>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      '& .btn + .btn': {
        marginLeft: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .btn + .btn': {
        marginTop: theme.spacing(2),
      },
    },
  },
})
