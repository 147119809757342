import MapIcon from '@mui/icons-material/Map'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DragableFileUpload from '../../components/DragableFileUpload'
import Footer from '../../components/Footer/Footer'
import { GlobalSnackbarProps } from '../../components/GlobalSnackbar'
import PageTitle from '../../components/PageTitle/PageTitle'
import Stepper from '../../components/Stepper'
import {
  useGeneratePresignedUploadCsvFileMutation,
  useGetPropertyAssetListQuery,
  useRemovePropertyAssetListMutation,
  useSavePropertyAssetListMutation,
} from '../../generated/telecomGraphqlService'
import useAppContext from '../../hooks/useAppContext'
import useAuth from '../../hooks/useAuth'
import { putFileInAzureStorage } from '../../utils/api'
import { goToLeasedAssetList } from '../../utils/routeActions'
import theme from '../../utils/theme'

export default function PropertyAssetListPage(): JSX.Element {
  const { t } = useTranslation()
  const classes = useStyles()

  const { setGlobalSnackbarProps } = useAppContext()
  const [validationReport, setValidationReport] = React.useState<string | null>(null)
  const [previousFileURL, setPreviousFileURL] = React.useState<string | null>(null)
  const [previousFileName, setPreviousFileName] = React.useState<string | null>(null)
  const [validationReportName, setValidationReportName] = React.useState<string | null>(null)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = React.useState<string>('')
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false)
  const [hasFormErrors, setHasFormErrors] = React.useState(false)
  const { user, authInformation } = useAuth()
  const { data, isLoading: getProperyAssetListIsLoading, refetch } = useGetPropertyAssetListQuery(
    { accountNumber: user?.accountNumber },
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: true,
      onSuccess(response) {
        if (!validationReport && response.getPropertyAssetList?.validationReport) {
          setValidationReport(response.getPropertyAssetList?.validationReport)
          setValidationReportName(response.getPropertyAssetList?.validationReportName as string)
        }
      },
    },
  )
  const { mutateAsync: removePropertyAssetListMutation, isLoading: isRemovingFile } = useRemovePropertyAssetListMutation(
    {
      onSuccess() {
        setValidationReport(null)
        refetch()
      },
    },
  )
  const { mutateAsync: generatePresignedToken } = useGeneratePresignedUploadCsvFileMutation()
  const { mutateAsync: savePropertyAssetListMutation } = useSavePropertyAssetListMutation()

  const onSubmit = async (file: File): Promise<void> => {
    try {
      
      setIsFormSubmitting(true)
      setHasFormErrors(false)

      
      const { generatePresignedUploadCsvFile } = await generatePresignedToken({
        isLeased: false,
      })

      await putFileInAzureStorage(generatePresignedUploadCsvFile?.url as string, file)

      const { savePropertyAssetList } = await savePropertyAssetListMutation({
        accountNumber: user.accountNumber,
        userId: String(authInformation.userId),
        fileName: generatePresignedUploadCsvFile?.fileName as string,
      })

      const fileName = generatePresignedUploadCsvFile?.fileName as string

      if (savePropertyAssetList?.fileName) {
        setPreviousFileURL(savePropertyAssetList?.fileName)
        setPreviousFileName(fileName)
      }

      if (savePropertyAssetList?.validationReport) {
        setValidationReport(savePropertyAssetList.validationReport)
        setValidationReportName(fileName.replace('.csv', '_Error.pdf'))
        setIsFormSubmitting(false)
        return
      }

      setIsFormSubmitting(false)
      setGlobalSnackbarProps?.({
        message: t('itemSaved', { item: t('propertyAssetList') }),
      } as GlobalSnackbarProps)
    } catch (error: unknown) {
      setHasFormErrors(true)
      setIsFormSubmitting(false)
      setSnackbarErrorMessage(error instanceof Error ? error.message : t('oopsSomethingWentWrong'))
      return
    }

    setHasFormErrors(false)
  }

  const removeValidationReport = (): void => {
    setValidationReport(null)
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarErrorMessage('')
  }

  return (
    <>
      <Stepper activeStep={1} />

      <Box component="header">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <PageTitle title={t('propertyAssetList')} />

          <Box className={classes.container}>
            <Button
              href="https://propertytax.utah.gov/personal/classification-guide/"
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              className="btn"
            >
              {t('classificationGuide')}
            </Button>
            <Button
              href="https://opendata.gis.utah.gov/datasets/utah::utah-tax-areas-2021/explore?location=39.472886%2C-111.547240%2C-1.00"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<MapIcon />}
              variant="outlined"
              className="btn"
            >
              {t('taxAreaMap')}
            </Button>
            <Button
              href={data?.getPropertyAssetList?.countyContactsURL as string}
              download={data?.getPropertyAssetList?.countyContactsURL}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              disabled={!data?.getPropertyAssetList?.countyContactsURL}
              className="btn"
            >
              {t('countyContacts')}
            </Button>
          </Box>
        </Stack>

        <ul className={classes.instructionsList}>
          <li>
            <Typography>{t('propertyAssetListInstructions1')}</Typography>
          </li>
          <li>
            <Typography>{t('propertyAssetListInstructions2')}</Typography>
          </li>
          <li>
            <Typography><b>{t('propertyAssetListInstructions3')}</b></Typography>
          </li>
          <li>
            <Typography>{t('propertyAssetListInstructions4')}</Typography>
          </li>
        </ul>
      </Box>

      <DragableFileUpload
        validationReport={validationReport}
        validationReportName={validationReportName}
        removeValidationReport={removeValidationReport}
        downloadTemplateURL={data?.getPropertyAssetList?.templateURL}
        previousFileURL={previousFileURL ?? data?.getPropertyAssetList?.fileUrl}
        onSubmit={onSubmit}
        isLoading={getProperyAssetListIsLoading}
        hasErrors={hasFormErrors}
        isSubmitting={isFormSubmitting}
        isRemovingFile={isRemovingFile}
        fileIconName={previousFileName ?? data?.getPropertyAssetList?.fileName as string}
        navigateToNextStep={goToLeasedAssetList}
        setHasFormErrors={setHasFormErrors}
        onRemoveFile={removePropertyAssetListMutation}
      />

      <Snackbar autoHideDuration={5000} open={!!snackbarErrorMessage} onClose={handleCloseSnackbar}>
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
      <Footer />
    </>
  )
}

const useStyles = makeStyles({
  instructionsList: {
    paddingLeft: '1rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      '& .btn + .btn': {
        marginLeft: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .btn + .btn': {
        marginTop: theme.spacing(2),
      },
    },
  },
})
