import assert from 'assert'
import { Theme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import Snackbar from '@mui/material/Snackbar'
import MuiTextField, { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  EinField,
  EmailField,
  Paginator,
  PhoneField,
  StateField,
  TextField,
  ZipCodeField,
} from 'shared-components'
import type { State as ComponentState } from 'shared-components/material/StateField'

import Footer from '../../components/Footer/Footer'
import { GlobalSnackbarProps } from '../../components/GlobalSnackbar'
import Stepper from '../../components/Stepper/Stepper'
import {
  Account,
  AccountInput,
  ContactType,
  Maybe,
  State,
  useSaveAccountMutation,
} from '../../generated/telecomGraphqlService'
import useAppContext from '../../hooks/useAppContext'
import {
  createEditableAccount,
  isPrimaryContact,
  validateAccountInformationForm,
  EditableAccount,
  EditableAddress,
  EditableContact,
  createNewEditableContact,
} from '../../models/forms'
import History from '../../models/history'
import { getLabel, HasLabel, ordinal } from '../../utils'
import { goToPropertyAssetList } from '../../utils/routeActions'
import AddContactButton from './AddContactButton'
import BasicAccountInformation from './BasicAccountInformation'
import ChangePrimaryContactDialog from './ChangePrimaryContactDialog'
import ContactInformation from './ContactInformation'
import FakePaginator from './FakePaginator'
import RemoveNonPrimaryContactDialog from './RemoveNonPrimaryContactDialog'
import RemovePrimaryContactDialog from './RemovePrimaryContactDialog'
import Wrapper from './Wrapper'

interface Props {
  account: Account
  contactTypes: ContactType[]
}

export default function AccountInformationPage(props: Props): JSX.Element {
  const classes = useStyles()

  const navigate = useNavigate()
  const { setGlobalSnackbarProps } = useAppContext()
  const { t } = useTranslation()

  const [editableAccount, setEditableAccount] = React.useState<EditableAccount>(() => {
    return createEditableAccount(props.account)
  })

  const formRef = React.createRef<HTMLFormElement>()
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [errorText, setErrorText] = React.useState('')
  const [isChangePrimaryContactDialogOpen, setIsChangePrimaryContactDialogOpen] = React.useState(false)
  const [isRemoveNonPrimaryContactDialogOpen, setIsRemoveNonPrimaryContactDialogOpen] = React.useState(false)
  const [isRemovePrimaryContactDialogOpen, setIsRemovePrimaryContactDialogOpen] = React.useState(false)
  const [shouldRemoveCurrentContact, setShouldRemoveCurrentContact] = React.useState(false)

  const { mutateAsync: saveAccountMutation } = useSaveAccountMutation()

  React.useEffect((): void => {
    setEditableAccount(createEditableAccount(props.account))
  }, [props.account])

  const primaryConctactType = React.useMemo(() => {
    return props.contactTypes.find((contactType) => contactType.label === 'Primary') as ContactType
  }, [props.contactTypes])

  const primaryContact = React.useMemo(() => {
    const primaryContact = editableAccount.contacts.array.find(isPrimaryContact)
    assert(primaryContact !== undefined, noPrimaryContactAssertionError)

    return primaryContact
  }, [editableAccount.contacts])

  const currentContact = editableAccount.contacts.current
  const currentContactIndex = editableAccount.contacts.currentIndex
  const totalAmountOfContacts = editableAccount.contacts.array.length
  const tooFewContacts = totalAmountOfContacts <= 1

  const addHelperText = (value: string, total: number): string => {
    return t('someOfAll', { some: value ? value.length : '0', all: total })
  }

  const renderContactTypeInput = (params: AutocompleteRenderInputParams): JSX.Element => (
    <TextField {...params} label={t('contactType')} className={classes.marginBottom} required />
  )

  const renderStateInput = (params: AutocompleteRenderInputParams): JSX.Element => (
    <TextField {...params} label={t('state')} required={currentContact.isPrimary} />
  )

  const renderYearInput = React.useCallback(
    (params: MuiTextFieldPropsType): React.ReactElement => (
      <MuiTextField
        {...params}
        autoComplete="off"
        variant="standard"
        helperText={t('locationOpenedQuestion')}
        inputProps={{ ...params.inputProps }}
        fullWidth
      />
    ),
    [t],
  )

  const onCloseError = (): void => setHasError(false)

  const onYearOpenedChange = (yearOpened: Date | null): void => {
    setEditableAccount({ ...editableAccount, yearOpened })
  }

  const onBusinessDescriptionChange = (businessDescription: string): void => {
    setEditableAccount({ ...editableAccount, businessDescription })
  }

  const onRegisteredEntityNameChange = (registeredEntityName: string): void => {
    setEditableAccount({ ...editableAccount, registeredEntityName })
  }

  const onEinChange = (employerIdentificationNumber: string): void => {
    setEditableAccount({ ...editableAccount, employerIdentificationNumber })
  }

  const onContactsChange = (contacts: History<EditableContact>): void => {
    setEditableAccount({ ...editableAccount, contacts })
  }

  const onNextContact = (): void => onContactsChange(editableAccount.contacts.forward(true))
  const onPreviousContact = (): void => onContactsChange(editableAccount.contacts.back(true))
  const onGoToPrimaryContact = (): void =>
    onContactsChange(editableAccount.contacts.selectCurrent(isPrimaryContact))

  const onCurrentContactChange = (contact: Partial<EditableContact>): void => {
    onContactsChange(editableAccount.contacts.replaceCurrent({ ...currentContact, ...contact }))
  }

  const onAddContact = (): void => {
    const newContact = createNewEditableContact()
    onContactsChange(editableAccount.contacts.add(newContact))
  }
  const onRemoveContact = (): void => {
    setShouldRemoveCurrentContact(true)
    if (currentContact.isPrimary) {
      setIsRemovePrimaryContactDialogOpen(true)
    } else {
      setIsRemoveNonPrimaryContactDialogOpen(true)
    }
  }
  const onChangePrimaryContactDialogClose = (): void => setIsChangePrimaryContactDialogOpen(false)
  const onChangePrimaryContact = (): void => {
    onChangePrimaryContactDialogClose()
    onContactsChange(
      editableAccount.contacts.map(
        mapCurrentContactToPrimary.bind(null, primaryConctactType, primaryContact, currentContact),
      ),
    )
  }
  const onSwitchPrimaryContact = (_event: React.SyntheticEvent<Element, Event>, checked: boolean): void => {
    if (checked) {
      if (!primaryContact.name) return onChangePrimaryContact()
      return setIsChangePrimaryContactDialogOpen(true)
    }
    setIsRemovePrimaryContactDialogOpen(true)
  }
  const onRemovePrimaryContactDialogClose = (): void => {
    setIsRemovePrimaryContactDialogOpen(false)
    setShouldRemoveCurrentContact(false)
  }
  const onConfirmNewPrimaryContact = (selectedContact: EditableContact): void => {
    onRemovePrimaryContactDialogClose()
    const { contacts } = editableAccount
    const mapper = mapCurrentContactToPrimary.bind(null, primaryConctactType, primaryContact, selectedContact)
    const newContacts = shouldRemoveCurrentContact
      ? contacts.withoutCurrent?.map(mapper).selectCurrent(isPrimaryContact)
      : contacts.map(mapper)
    assert(newContacts !== undefined, onlyOneContactAssertionError)
    onContactsChange(newContacts)
  }
  const onRemoveNonPrimaryContactDialogClose = (): void => {
    setIsRemoveNonPrimaryContactDialogOpen(false)
    setShouldRemoveCurrentContact(false)
  }
  const onConfirmDeleteNonPrimaryContact = (): void => {
    onRemoveNonPrimaryContactDialogClose()
    const newContacts = editableAccount.contacts.withoutCurrent
    assert(newContacts !== null, onlyOneContactAssertionError)
    onContactsChange(newContacts)
  }
  const onContactTypeChange = (
    _event: React.SyntheticEvent<Element, Event>,
    contactType: Maybe<ContactType>,
  ): void => {
    onCurrentContactChange({ contactType })
  }

  const onNameChange = (name: string): void => onCurrentContactChange({ name })
  const onEmailChange = (email: string): void => onCurrentContactChange({ email })
  const onPhoneChange = (phone: string): void => onCurrentContactChange({ phone })
  const onPhoneExtensionChange = (phoneExtension: string): void => onCurrentContactChange({ phoneExtension })
  const onCellphoneChange = (cellphone: string): void => onCurrentContactChange({ cellphone })

  const onUpdateMailingAddress = (changes: Partial<EditableAddress>): void => {
    return onCurrentContactChange({ mailingAddress: { ...currentContact.mailingAddress, ...changes } })
  }

  const onInCareOfChange = (inCareOf: string): void => onUpdateMailingAddress({ inCareOf })
  const onAddressLine1Change = (addressLine1: string): void => onUpdateMailingAddress({ addressLine1 })
  const onAddressLine2Change = (addressLine2: string): void => onUpdateMailingAddress({ addressLine2 })
  const onCityChange = (city: string): void => onUpdateMailingAddress({ city })
  const onStateChange = (_event: React.SyntheticEvent<Element, Event>, state: ComponentState | null): void =>
    onUpdateMailingAddress({ state: state as State })
  const onZipCodeChange = (zipCode: string): void => onUpdateMailingAddress({ zipCode })

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    const { account, contacts } = validateAccountInformationForm(editableAccount)

    if (!contacts.valid) {
      const [firstErrorContactIndex, ...otherErrorContactIndices] = contacts.metadata

      onContactsChange(editableAccount.contacts.go(firstErrorContactIndex))

      if (otherErrorContactIndices.length > 0) {
        setHasError(true)
        setErrorText(
          t('otherContactsError', {
            count: otherErrorContactIndices.length,
            positions: otherErrorContactIndices.map(ordinal),
          }),
        )
      }

      // HTML Validation
      const form = formRef.current

      await Promise.resolve()

      form?.reportValidity()

      return
    }

    if (!account.valid) return

    setIsSubmitDisabled(true)

    try {
      await saveAccountMutation({
        account: account.result as AccountInput,
      })

      setGlobalSnackbarProps?.({
        message: t('itemSaved', { item: t('accountInformation') }),
      } as GlobalSnackbarProps)
      goToPropertyAssetList(navigate)
    } catch (e) {
      setHasError(true)
      setErrorText(e instanceof Error ? e.message : t('oopsSomethingWentWrong'))
      setIsSubmitDisabled(false)
    }
  }

  return (
    <React.Fragment>
      <Stepper activeStep={0} />
      <Wrapper
        ref={formRef}
        isSubmitDisabled={isSubmitDisabled}
        onSubmit={onSubmit}
        leftPanel={
          <BasicAccountInformation
            yearOpenedField={
              <DatePicker
                label={t('yearOpened')}
                value={editableAccount.yearOpened}
                onChange={onYearOpenedChange}
                renderInput={renderYearInput}
                views={onlyShowYear}
                maxDate={today}
                minDate={minYearOpenedDate}
                autoFocus
              />
            }
            businessDescriptionField={
              <TextField
                id="businessDescription"
                label={t('businessDescription')}
                value={editableAccount.businessDescription}
                onChange={onBusinessDescriptionChange}
                inputProps={businessDescriptionInputProps}
                helperText={addHelperText(
                  editableAccount.businessDescription,
                  businessDescriptionInputProps.maxLength,
                )}
                fullWidth
              />
            }
            registeredEntityNameField={
              <TextField
                id="registeredEntityName"
                label={t('registeredEntityName')}
                value={editableAccount.registeredEntityName}
                onChange={onRegisteredEntityNameChange}
                inputProps={registeredEntityNameInputProps}
                helperText={addHelperText(
                  editableAccount.registeredEntityName,
                  registeredEntityNameInputProps.maxLength,
                )}
                fullWidth
              />
            }
            employerNotificationNumberField={
              <EinField
                id="ein"
                label={t('employerNotificationNumber')}
                helperText={`${t('endSentence', {
                  sentence: t('alsoKnownAs', { value: t('federalTaxIdentificationNumber') }),
                })}
                  ${t('noSocialSecurityNumber')}`}
                InputLabelProps={{ shrink: !!editableAccount.employerIdentificationNumber }}
                value={editableAccount.employerIdentificationNumber}
                onChange={onEinChange}
                fullWidth
              />
            }
          />
        }
        rightPanel={
          <ContactInformation
            middleContent={
              tooFewContacts ? (
                <FakePaginator />
              ) : (
                <Paginator
                  index={currentContactIndex}
                  total={totalAmountOfContacts}
                  nextLabel={t('nextContact')}
                  previousLabel={t('previousContact')}
                  resetLabel={t('primaryContact')}
                  onNext={onNextContact}
                  onPrevious={onPreviousContact}
                  onReset={onGoToPrimaryContact}
                  variant="text"
                  size="small"
                  color="inherit"
                  classes={{ groupedTextHorizontal: classes.groupedTextHorizontal }}
                />
              )
            }
            endContent={<AddContactButton onClick={onAddContact} />}
            topContactSection={
              <Box display="flex" justifyContent="space-between" marginBottom={2} flexDirection="column">
                <Box display="flex" justifyContent="space-between">
                  <FormControlLabel
                    key={`primary-contact-${currentContact.id}`}
                    label={t('primaryContact')}
                    checked={currentContact.isPrimary}
                    disabled={tooFewContacts}
                    onChange={onSwitchPrimaryContact}
                    control={<Checkbox color="primary" />}
                  />
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={onRemoveContact}
                    disabled={tooFewContacts}
                  >
                    {t('removeContact')}
                  </Button>
                </Box>
                {currentContact.isPrimary && (
                  <Box>
                    <Typography variant="subtitle2">
                      {t('primaryContactInformation')}
                    </Typography>
                  </Box>
                )}
              </Box>
            }
            contactTypeField={
              <Autocomplete
                key={`contact-type-${currentContact.id}`}
                id="contactType"
                options={props.contactTypes}
                filterOptions={(contactTypes): ContactType[] =>
                  contactTypes.filter(filterContactType(currentContact.isPrimary))
                }
                value={currentContact.contactType}
                onChange={onContactTypeChange}
                getOptionLabel={getLabel}
                isOptionEqualToValue={getOptionSelected}
                renderInput={renderContactTypeInput}
                disabled={currentContact.isPrimary}
              />
            }
            nameField={
              <TextField
                key={`name-${currentContact.id}`}
                id="name"
                label={t('name')}
                value={currentContact.name}
                onChange={onNameChange}
                inputProps={nameInputProps}
                helperText={addHelperText(currentContact.name, nameInputProps.maxLength)}
                autoComplete="off"
                required
                fullWidth
              />
            }
            emailField={
              <EmailField
                key={`email-${currentContact.id}`}
                id="email"
                label={t('email')}
                value={currentContact.email}
                onChange={onEmailChange}
                inputProps={emailInputProps}
                helperText={addHelperText(currentContact.email, emailInputProps.maxLength)}
                autoComplete="off"
                required
                fullWidth
              />
            }
            phoneField={
              <PhoneField
                key={`phone-${currentContact.id}`}
                id="phone"
                label={t('phone')}
                value={currentContact.phone}
                onChange={onPhoneChange}
                helperText={addHelperText(currentContact.phone, phoneInputProps.maxLength)}
                required
                fullWidth
              />
            }
            phoneExtensionField={
              <TextField
                key={`phone-extension-${currentContact.id}`}
                id="phoneExtension"
                label={t('phoneExtension')}
                value={currentContact.phoneExtension}
                onChange={onPhoneExtensionChange}
                inputProps={phoneExtensionInputProps}
                helperText={addHelperText(currentContact.phoneExtension, phoneExtensionInputProps.maxLength)}
                fullWidth
              />
            }
            cellphoneField={
              <PhoneField
                key={`cellphone-${currentContact.id}`}
                id="cellphone"
                label={t('cellphone')}
                value={currentContact.cellphone}
                onChange={onCellphoneChange}
                fullWidth
              />
            }
            mailingAddressHeader={
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" component="h4">
                  {t('mailingAddress')}
                </Typography>
              </Box>
            }
            inCareOfField={
              <TextField
                key={`in-care-of-${currentContact.id}`}
                id="inCareOf"
                label={t('inCareOf')}
                value={currentContact.mailingAddress.inCareOf}
                onChange={onInCareOfChange}
                inputProps={inCareOfInputProps}
                helperText={addHelperText(currentContact.mailingAddress.inCareOf, inCareOfInputProps.maxLength)}
                fullWidth
              />
            }
            addressLine1Field={
              <TextField
                key={`address-line-1-${currentContact.id}`}
                id="addressLine1"
                label={t('addressLine1')}
                value={currentContact.mailingAddress.addressLine1}
                onChange={onAddressLine1Change}
                inputProps={addressLine1InputProps}
                helperText={addHelperText(
                  currentContact.mailingAddress.addressLine1,
                  addressLine1InputProps.maxLength,
                )}
                required={currentContact.isPrimary}
                fullWidth
              />
            }
            addressLine2Field={
              <TextField
                key={`address-line-2-${currentContact.id}`}
                id="addressLine2"
                label={t('addressLine2')}
                value={currentContact.mailingAddress.addressLine2}
                onChange={onAddressLine2Change}
                inputProps={addressLine2InputProps}
                helperText={addHelperText(
                  currentContact.mailingAddress.addressLine2,
                  addressLine2InputProps.maxLength,
                )}
                fullWidth
              />
            }
            cityField={
              <TextField
                key={`city-${currentContact.id}`}
                className={classes.leftTextField}
                id="city"
                label={t('city')}
                value={currentContact.mailingAddress.city}
                onChange={onCityChange}
                inputProps={cityInputProps}
                helperText={addHelperText(currentContact.mailingAddress.city, cityInputProps.maxLength)}
                required={currentContact.isPrimary}
              />
            }
            stateField={
              <StateField
                key={`state-${currentContact.id}`}
                className={classes.leftTextField}
                id="state"
                value={currentContact.mailingAddress.state}
                onChange={onStateChange}
                renderInput={renderStateInput}
              />
            }
            zipCodeField={
              <ZipCodeField
                key={`zip-${currentContact.id}`}
                id="zip"
                label={t('zip')}
                value={currentContact.mailingAddress.zipCode}
                onChange={onZipCodeChange}
                className={classes.zipCodeField}
                required={currentContact.isPrimary}
                helperText={addHelperText(currentContact.mailingAddress.zipCode, zipCodeInputProps.maxLength)}
              />
            }
          />
        }
      />
      <ChangePrimaryContactDialog
        primaryContact={primaryContact}
        isOpen={isChangePrimaryContactDialogOpen}
        onClose={onChangePrimaryContactDialogClose}
        onChange={onChangePrimaryContact}
      />
      <RemovePrimaryContactDialog
        contacts={editableAccount.contacts.array}
        isOpen={isRemovePrimaryContactDialogOpen}
        onClose={onRemovePrimaryContactDialogClose}
        onRemove={onConfirmNewPrimaryContact}
      />
      <RemoveNonPrimaryContactDialog
        currentContact={currentContact}
        isOpen={isRemoveNonPrimaryContactDialogOpen}
        onClose={onRemoveNonPrimaryContactDialogClose}
        onRemove={onConfirmDeleteNonPrimaryContact}
      />
      <Snackbar open={hasError} onClose={onCloseError}>
        <Alert severity="error" variant="filled" onClose={onCloseError}>
          {errorText}
        </Alert>
      </Snackbar>
      <Footer />
    </React.Fragment>
  )
}

const onlyShowYear: Array<'year'> = ['year']
const businessDescriptionInputProps: InputBaseComponentProps = { maxLength: 50 }
const registeredEntityNameInputProps: InputBaseComponentProps = { maxLength: 50 }
const nameInputProps: InputBaseComponentProps = { maxLength: 50 }
const emailInputProps: InputBaseComponentProps = { maxLength: 50 }
const phoneInputProps: InputBaseComponentProps = { maxLength: 14 }
const phoneExtensionInputProps: InputBaseComponentProps = { maxLength: 10 }
const zipCodeInputProps: InputBaseComponentProps = { maxLength: 10 }
const inCareOfInputProps: InputBaseComponentProps = { maxLength: 50 }
const addressLine1InputProps: InputBaseComponentProps = { maxLength: 30 }
const addressLine2InputProps: InputBaseComponentProps = { maxLength: 30 }
const cityInputProps: InputBaseComponentProps = { maxLength: 30 }
const onlyOneContactAssertionError =
  'There is only one Contact. This callback assumed that there were at least two Contacts.'
const noPrimaryContactAssertionError =
  'There is no Primary Contact. This callback assumed that there is one Primary Contact.'
const today = new Date()
const minYearOpenedDate = new Date('01-01-1583')

function mapCurrentContactToPrimary(
  primaryConctactType: ContactType,
  oldPrimaryContact: EditableContact,
  newPrimaryContact: EditableContact,
  contact: EditableContact,
): EditableContact {
  if (contact.id === oldPrimaryContact.id) return { ...contact, isPrimary: false, contactType: null }
  if (contact.id === newPrimaryContact.id) return { ...contact, isPrimary: true, contactType: primaryConctactType }
  return contact
}

function getOptionSelected<T extends HasLabel>(option: T, value: T): boolean {
  return getLabel(option) === getLabel(value)
}

function filterContactType(isPrimary: boolean) {
  return (contactTypeOption: ContactType): boolean | void => {
    if (!isPrimary) {
      return contactTypeOption.label !== 'Primary'
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  leftTextField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flex: 2,
  },
  zipCodeField: {
    flex: 1,
  },
  groupedTextHorizontal: { borderRight: 'none !important' },
}))
