import AccessTime from '@mui/icons-material/AccessTime'
import CalendarToday from '@mui/icons-material/CalendarToday'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Stack, Theme } from '@mui/system'
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { APP_TIMEZONE } from '../../../config'
import { HOUR_FORMAT, ORDINAL_DATE_FORMAT, UTC_TIMEZONE } from '../../../utils/constants'

import TextWithIcon from './TextWithIcon'

export interface AcceptedForProps {
  telecomUsername: string
  telecomName: string
  date: string
  time: string
}

export default function AcceptedFor({ telecomUsername, telecomName, date, time }: AcceptedForProps): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()

  const submittedDatetime = zonedTimeToUtc(new Date(`${date} ${time}`), UTC_TIMEZONE)
  const submittedDate = formatInTimeZone(submittedDatetime, APP_TIMEZONE, ORDINAL_DATE_FORMAT)
  const submittedTime = formatInTimeZone(submittedDatetime, APP_TIMEZONE, HOUR_FORMAT)

  return (
    <Stack flex={1} alignSelf="center">
      <Typography variant="h6" component="h3" mb={2}>
        {t('thankYouAcceptedFor')}
      </Typography>

      <Paper className={classes.card} elevation={6}>
        <Stack spacing={1} p={3} alignItems="left" className={classes.acceptedFilingText}>

          <Typography component="h3" color="primary" sx={{ typography: { xs: 'h6', sm: 'h5', lg: 'h4' } }}>
            <Typography className={classes.accountNumberText} variant="subtitle1" component="span" color="GrayText">
              #{telecomUsername}
            </Typography>
            {telecomName}
          </Typography>

          <Typography variant="subtitle1">
            {t('thankYouFilingSubmissionDatetime')}
          </Typography>

          <TextWithIcon
            icon={<CalendarToday />}
            label={submittedDate}
          />

          <TextWithIcon
            icon={<AccessTime />}
            label={submittedTime}
          />
        </Stack>
      </Paper>
    </Stack>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: '100%',
    maxWidth: 400,
  },
  accountNumberText: {
    display: 'block',
    color: theme.palette.secondary.main,
  },
  acceptedFilingText: {
    color: theme.palette.grey[700],
  },
}))
