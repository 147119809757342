import React, { useState } from 'react'
import Loader from '../../components/Loader'
import LoadingFetchError from '../../components/LoadingFetchError'

import { SessionContext, useSessionContextQuery } from '../../generated/telecomGraphqlService'
import useAuth from '../../hooks/useAuth'

import StepRedirector from '../StepRedirector'

export default function PostLogin(): JSX.Element {
  const { storeSessionContext, signOut } = useAuth()
  const [ storedResponse, setStoredResponse] = useState(false)
  const { data, refetch, isError, isLoading } = useSessionContextQuery({}, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (response) => {
      storeSessionContext?.(response.getSessionContext as SessionContext)
      setStoredResponse(true)
    },
  })

  if (isError) {
    signOut?.()
    return <LoadingFetchError tryAgain={refetch} />
  }

  if (isLoading || !data?.getSessionContext || !storedResponse) {
    return <Loader loading={isLoading} />
  }

  return <StepRedirector />
}
