import Alert from '@mui/material/Alert'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Paper from '@mui/material/Paper'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CsvSVG } from '../../svgs/csv.svg'
import { ReactComponent as PdfSVG } from '../../svgs/pdf.svg'

interface Props {
  validationReport: string
  validationReportName: string
  previousFileURL?: string | null
  fileIconName: string
}

export default function ValidationReportList(props: Props): JSX.Element {
  const { t } = useTranslation()

  return (
    <Paper>
      <Alert severity="error">{t('weFoundSomeErrorsPleaseCheckThisReport')}</Alert>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t('reportAndSubmittedFile')}
          </ListSubheader>
        }
        dense
      >
        <ListItemButton component="a" href={props.validationReport}>
          <ListItemIcon>
            <PdfSVG width="1.5rem" />
          </ListItemIcon>
          <ListItemText primary={props.validationReportName} />
        </ListItemButton>

        {props.previousFileURL && (
          <ListItemButton component="a" href={props.previousFileURL}>
            <ListItemIcon>
              <CsvSVG width="1.5rem" />
            </ListItemIcon>
            <ListItemText primary={props.fileIconName} />
          </ListItemButton>
        )}
      </List>
    </Paper>
  )
}
