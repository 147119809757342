export default class History<T> {

  private readonly _previousStack: T[]
  private readonly _current: T
  private readonly _remainingQueue: T[]

  private constructor(previousStack: T[], current: T, remainingQueue: T[]) {
    // Stored in reverse order
    this._previousStack = previousStack
    this._current = current
    this._remainingQueue = remainingQueue
  }

  public get array(): T[] {
    return [...reverse(this._previousStack), this._current, ...this._remainingQueue]
  }

  public get current(): T {
    return this._current
  }

  public get currentIndex(): number {
    return this._previousStack.length
  }

  public get withoutCurrent(): History<T> | null {
    const [lastOfPrevious, ...restOfPrevious] = this._previousStack

    if (lastOfPrevious) {
      return new History(restOfPrevious, lastOfPrevious, this._remainingQueue)
    }

    const [firstOfRemaining, ...restOfRemaining] = this._remainingQueue

    if (firstOfRemaining) {
      return new History(this._previousStack, firstOfRemaining, restOfRemaining)
    }

    return null
  }

  public add(current: T): History<T> {
    return new History(
      [...reverse(this._remainingQueue), this._current, ...this._previousStack],
      current,
      [],
    )
  }

  public back(shouldWrap?: boolean): History<T> {
    const [lastOfPrevious, ...restOfPrevious] = this._previousStack

    if (lastOfPrevious) {
      return new History(
        restOfPrevious,
        lastOfPrevious,
        [this._current, ...this._remainingQueue],
      )
    }

    if (!shouldWrap) return this

    const lastOfRemaining = this._remainingQueue[this._remainingQueue.length - 1]

    if (!lastOfRemaining) return this

    const restOfRemaining = this._remainingQueue.slice(0, -1)
    return new History([...restOfRemaining, this._current], lastOfRemaining, [])
  }

  public forward(shouldWrap?: boolean): History<T> {
    const [firstOfRemaining, ...restOfRemaining] = this._remainingQueue

    if (firstOfRemaining) {
      return new History(
        [this._current, ...this._previousStack],
        firstOfRemaining,
        restOfRemaining,
      )
    }

    if (!shouldWrap) return this

    const firstOfPreviousIndex = this._previousStack.length - 1
    const firstOfPrevious = this._previousStack[firstOfPreviousIndex]

    if (!firstOfPrevious) return this

    const restOfPrevious = this._previousStack.slice(0, -1)
    return History.init(firstOfPrevious, [...reverse(restOfPrevious), this._current])
  }

  public go(index: number): History<T> {
    return this.selectCurrent((_value, i) => index === i)
  }

  public map<U>(mapper: (value: T, index: number) => U): History<U> {
    return new History(
      this._previousStack.map(mapper),
      mapper(this._current, this.currentIndex),
      this._remainingQueue.map((value, i) => mapper(value, remainingIndex(this.currentIndex, i))),
    )
  }

  public replaceCurrent(current: T): History<T> {
    return new History(this._previousStack, current, this._remainingQueue)
  }

  public selectCurrent(predicate: (value: T, index: number) => boolean): History<T> {
    const currentInPreviousIndex = reverse(this._previousStack).findIndex(predicate)

    if (currentInPreviousIndex >= 0) {
      const [
        beginningOfNewRemaining,
        newCurrent,
        newPrevious,
      ] = partition(this._previousStack.length - currentInPreviousIndex - 1, this._previousStack)

      return new History(
        newPrevious,
        newCurrent,
        [...reverse(beginningOfNewRemaining), this._current, ...this._remainingQueue],
      )
    }

    if (predicate(this._current, this.currentIndex)) return this

    const currentInRemainingIndex = this._remainingQueue.findIndex((value, i) => {
      return predicate(value, remainingIndex(this.currentIndex, i))
    })

    if (currentInRemainingIndex < 0) return this

    const [
      endOfNewPrevious,
      newCurrent,
      newRemaining,
    ] = partition(currentInRemainingIndex, this._remainingQueue)

    return new History(
      [...reverse(endOfNewPrevious), this._current, ...this._previousStack],
      newCurrent,
      newRemaining,
    )
  }

  public static init<T>(current: T, remaining: T[]): History<T> {
    return new History([], current, remaining)
  }
}

function partition<T>(index: number, array: T[]): [T[], T, T[]] {
  return [
    array.slice(0, index),
    array[index],
    array.slice(index + 1),
  ]
}

function remainingIndex(currentIndex: number, index: number): number {
  return currentIndex + index + 1
}

function reverse<T>(array: T[]): T[] {
  return [...array].reverse()
}
