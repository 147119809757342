import Skeleton from '@mui/material/Skeleton'
import React from 'react'

export default function SummaryAndSignatureSkeleton(): JSX.Element {
  return (
    <React.Fragment>
      <Skeleton height={104} />
      <Skeleton height={104} />
    </React.Fragment>
  )
}
