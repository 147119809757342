import { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Summary } from '../../generated/telecomGraphqlService'
import { formatMoney } from '../../utils'

interface Props {
  summary: Summary
}

export default function SummaryTable(props: Props): JSX.Element {
  const { assetDeclared, netTotal } = props.summary

  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.responsiveTable} padding="10">
      <Table className={classes.summaryTable} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>{t('assets')}</TableCell>
            <TableCell className={classes.tableHeader}>{t('units')}</TableCell>
            <TableCell className={classes.tableHeader}>{t('cost')}</TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('value')}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>{t('assetsDeclared')}</TableCell>
            <TableCell>{assetDeclared?.units}</TableCell>
            <TableCell>{formatMoney(assetDeclared?.cost as number)}</TableCell>
            <TableCell align="right">{formatMoney(assetDeclared?.value as number)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t('netTotal')}</TableCell>
            <TableCell />
            <TableCell>{formatMoney(netTotal?.cost as number)}</TableCell>
            <TableCell align="right">{formatMoney(netTotal?.value as number)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  responsiveTable: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  tableHeader: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  summaryTable: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  netTotalCell: {
    paddingBottom: theme.spacing(5),
  },
  totalTaxFooter: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  totalTaxFooterCell: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
  },
  noBottomBorder: {
    borderBottom: 0,
  },
  colorRed: {
    color: theme.palette.error.main,
  },
}))
