import Typography from '@mui/material/Typography'
import Stack from '@mui/system/Stack'
import React from 'react'

interface Props {
  icon: JSX.Element
  label: string
}

export default function TextWithIcon(props: Props): JSX.Element {
  return (
    <Stack flexDirection="row" columnGap={1}>
      {props.icon}
      <Typography component="h3">{props.label}</Typography>
    </Stack>
  )
}
