import Button, { ButtonProps } from '@mui/material/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'

type OmittedProps =
  | 'variant'
  | 'size'
  | 'color'
  | 'children'

type Props = Omit<ButtonProps, OmittedProps>

export default function AddContactButton(props: Props): JSX.Element {

  const { t } = useTranslation()

  return (
    <Button {...props} variant="text" size="small" color="inherit">
      {t('addContact')}
    </Button>
  )
}
