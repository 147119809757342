import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from 'shared-components'
import type { PanelProps } from 'shared-components/material/Panel'

interface Props extends Omit<PanelProps, 'classes' | 'headerText'> {
  yearOpenedField: React.ReactNode
  businessDescriptionField: React.ReactNode
  registeredEntityNameField: React.ReactNode
  employerNotificationNumberField: React.ReactNode
}

export default function BasicAccountInformation(props: Props): JSX.Element {

  const {
    yearOpenedField,
    businessDescriptionField,
    registeredEntityNameField,
    employerNotificationNumberField,
    ...panelProps
  } = props

  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Panel
      {...panelProps}
      headerText={t('basicAccountInformation')}
      headerTextComponent="h3"
      classes={{ root: classes.root }}
    >
      <Stack padding={3} spacing={1.5}>
        {yearOpenedField}
        {businessDescriptionField}
        {registeredEntityNameField}
        {employerNotificationNumberField}
      </Stack>
    </Panel>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'block',
    borderRadius: 4,
    height: 'auto',
    overflow: 'hidden',
  },
})
