import { Button } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SessionContext } from '../../generated/telecomGraphqlService'

interface Props {
  onClose(): void
  delinquentTaxYears: SessionContext['delinquentTaxYears']
}

export default function DelinquentBanner(props: Props): JSX.Element {
  const { t } = useTranslation()

  return (
    <Alert severity="error" action={<Button onClick={props.onClose}>{t('dismiss')}</Button>}>
      <AlertTitle>{t('delinquentNotice')}</AlertTitle>

      {t('delinquentNoticeMessage', { taxYears: props.delinquentTaxYears })}
    </Alert>
  )
}
