import React from 'react'
import LoadingFetchError from '../../components/LoadingFetchError'

import { OathStatement, useGetOathStatementQuery, useGetSummaryQuery } from '../../generated/telecomGraphqlService'

import SummaryAndSignaturePage from './SummaryAndSignaturePage'
import SummaryAndSignatureSkeleton from './SummaryAndSignatureSkeleton'

export default function SummaryAndSignature(): JSX.Element {

  const {
    data: summaryResponse,
    isLoading: summaryIsLoading,
    isError: hasSummaryError,
    refetch: refetchGetSummary,
  } = useGetSummaryQuery(
    { },
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: true,
    },
  )

  const {
    data,
    isLoading: oathStatementIsLoading,
    isError: hasOathStatementError,
    refetch: refetchGetOathStatement,
  } = useGetOathStatementQuery(
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: true,
    },
  )

  const isPageLoading = summaryIsLoading || oathStatementIsLoading

  const onRefetch = (): void => {
    if(hasSummaryError) {
      refetchGetSummary()
    }

    if(hasOathStatementError) {
      refetchGetOathStatement()
    }
  }

  if (hasSummaryError) {
    return <LoadingFetchError tryAgain={onRefetch} />
  }

  if (isPageLoading || !summaryResponse?.getSummary) {
    return <SummaryAndSignatureSkeleton />
  }

  const { getSummary: summary } = summaryResponse
  const  oathStatement = data?.getOathStatement as OathStatement

  return <SummaryAndSignaturePage summary={summary} oathStatement={oathStatement} />
}
