import { Link, Theme, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import React from 'react'

export default function Footer(): JSX.Element {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <hr className={classes.separator} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>For technical support, please contact&nbsp;</Typography>
        <Link href="tel:+13854040156" variant="body2">
          (385) 404-0156
        </Link>
        <Typography>&nbsp;or&nbsp;</Typography>
        <Link href="tel:+18013998575" variant="body2">
          (801) 399-8575
        </Link>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    width: '100%',
    borderTop: '1px solid #bbb',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(6),
  },
}))
