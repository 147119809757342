import { HelpOutlined, Newspaper } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

type Modal = 'news' | 'information'

export default function InformationModals(): JSX.Element {
  const [open, setOpen] = useState(false)
  const [currentModal, setCurrentModal] = useState<Modal>('news')

  const { t } = useTranslation()

  const handleOpen = (type: Modal): void => {
    setCurrentModal(type)
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Button startIcon={<HelpOutlined />} variant="outlined" onClick={(): void => handleOpen('information')}>
          {t('loginInformationButton')}
        </Button>

        <Button startIcon={<Newspaper />} variant="outlined" onClick={(): void => handleOpen('news')}>
          {t('loginNewsButton')}
        </Button>

        <Dialog fullWidth maxWidth="md" open={open && currentModal === 'news'} onClose={handleClose}>
          <DialogTitle>{t('loginNewFor')}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText tabIndex={-1} component="div">
              <Typography align="justify" variant="body1">
                {t('loginNewForTextItem1')}
              </Typography>
              <Typography align="justify" variant="body1">
                {t('loginNewForTextItem2')}
              </Typography>
              <Typography align="justify" variant="body1">
                {t('loginNewForTextItem3')}
              </Typography>
              <Typography align="justify" variant="body1">
                <Trans i18nKey="loginNewForTextItem4" />
              </Typography>
              <Typography align="justify" variant="body1">
                {t('loginNewForTextItem5')}
              </Typography>
              <Typography align="justify" variant="body1" whiteSpace="pre-line">
                {t('loginNewForTextItem6')}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('close')}</Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth maxWidth="md" open={open && currentModal === 'information'} onClose={handleClose}>
          <DialogTitle>{t('loginOnlineFilingInformation')}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <Box alignSelf="center" component="img" alt={t('loginNoticeExampleAlt')} src="images/notice.png" />
            </DialogContentText>
            <DialogContentText tabIndex={-1} component="div">
              <List sx={{ listStyleType: 'disc', paddingLeft: 2 }} disablePadding>
                <ListItem sx={{ display: 'list-item' }} disablePadding>
                  <ListItemText>
                    <Typography variant="body2">{t('loginOnlineFilingInformationLine1')}</Typography>
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }} disablePadding>
                  <ListItemText>
                    <Typography variant="body2">{t('loginOnlineFilingInformationLine2')}</Typography>
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }} disablePadding>
                  <ListItemText>
                    <Typography variant="body2">{t('loginOnlineFilingInformationLine3')}</Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('close')}</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  )
}
