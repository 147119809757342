import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FileExtension, Maybe } from '../../../generated/telecomGraphqlService'
import FileTile, { FileExt } from './FileTile'

interface UploadedFilesProps {
  leasedFile: FileExtension
  nonLeasedFile: FileExtension
}

export default function UploadedFiles(props: UploadedFilesProps): JSX.Element {
  const { leasedFile, nonLeasedFile } = props

  const { t } = useTranslation()
  const [nonLeasedFileExt, setNonLeasedFileExt] = useState<FileExt>(FileExt.pdf)
  const [leasedFileExt, setLeasedFileExt] = useState<FileExt>(FileExt.pdf)

  const leased = useMemo(() => {
    return leasedFileExt === FileExt.pdf ? leasedFile?.pdf : leasedFile?.csv
  }, [leasedFileExt, leasedFile.pdf, leasedFile.csv])

  const nonLeased = useMemo(() => {
    return nonLeasedFileExt === FileExt.pdf ? nonLeasedFile?.pdf : nonLeasedFile?.csv
  }, [nonLeasedFileExt, nonLeasedFile.pdf, nonLeasedFile.csv])

  const onNonleaseFileExtChange = (
    value: Maybe<string>
  ): void => {
    setNonLeasedFileExt(value as FileExt)
  }

  const onLeaseFileExtChange = (
    value: Maybe<string>
  ): void => {
    setLeasedFileExt(value as FileExt)
  }
  return (
    <>
      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
        {t('uploadedFiles')}
      </Typography>

      <Stack spacing={1} p={1} alignItems="center" direction={{ xs: 'column', md: 'row'}}>
        {nonLeased && nonLeased.fileUrl && (
          <Stack>
            <FileTile
              filename={nonLeased.filename as string}
              href={nonLeased.fileUrl as string}
              extension={nonLeasedFileExt}
              setExtension={onNonleaseFileExtChange}
            />
          </Stack>
        )}
        {leased && leased.fileUrl && (
          <Stack>
            <FileTile
              filename={leased.filename as string}
              href={leased.fileUrl as string}
              extension={leasedFileExt}
              setExtension={onLeaseFileExtChange}
            />
          </Stack>
        )}
      </Stack>
    </>
  )
}
