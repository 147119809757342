import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Panel } from 'shared-components'
import type { PanelProps } from 'shared-components/material/Panel'

interface Props extends Omit<PanelProps, 'classes' | 'headerText'> {
  topContactSection: React.ReactNode
  contactTypeField: React.ReactNode
  nameField: React.ReactNode
  emailField: React.ReactNode
  phoneField: React.ReactNode
  phoneExtensionField: React.ReactNode
  cellphoneField: React.ReactNode
  mailingAddressHeader: React.ReactNode
  inCareOfField: React.ReactNode
  addressLine1Field: React.ReactNode
  addressLine2Field: React.ReactNode
  cityField: React.ReactNode
  stateField: React.ReactNode
  zipCodeField: React.ReactNode
}

export default function ContactInformation(props: Props): JSX.Element {

  const {
    topContactSection,
    contactTypeField,
    nameField,
    emailField,
    phoneField,
    phoneExtensionField,
    cellphoneField,
    mailingAddressHeader,
    inCareOfField,
    addressLine1Field,
    addressLine2Field,
    cityField,
    stateField,
    zipCodeField,
    ...panelProps
  } = props

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Panel
      {...panelProps}
      headerText={t('contactInformation')}
      headerTextComponent="h3"
      classes={{ root: classes.panelRoot }}
    >
      <Stack spacing={2} padding={3}>
        {topContactSection}
        {contactTypeField}
        {nameField}
        {emailField}
        {phoneField}
        {phoneExtensionField}
        {cellphoneField}
      </Stack>

      <Stack spacing={2} padding={3}>
        {mailingAddressHeader}
        {inCareOfField}
        {addressLine1Field}
        {addressLine2Field}
        <Box display="flex" flexDirection="row">
          {cityField}
          {stateField}
          {zipCodeField}
        </Box>
      </Stack>
    </Panel>
  )
}

const useStyles = makeStyles({
  panelRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: 4,
    overflow: 'hidden',
  },
})
