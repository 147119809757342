import MenuIcon from '@mui/icons-material/Menu'
import { Theme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useAuth from '../../hooks/useAuth'
import DelinquentBanner from '../DelinquentBanner'
import NavigationDrawer from './NavigationDrawer'

export default function Header(): JSX.Element {

  const classes = useStyles()
  const { user, showDelinquentNoticeBanner, setShowDelinquentNoticeBanner } = useAuth()
  const { t } = useTranslation()

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const onDrawerOpen = (): void => setIsDrawerOpen(true)

  const onDrawerClose = (): void => setIsDrawerOpen(false)

  const onDismissDelinquentBannerClose = (): void => setShowDelinquentNoticeBanner?.(false)

  return (
    <Box className={classes.wrapper}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onDrawerOpen}
          >
            <MenuIcon />
          </IconButton>

          <Stack direction={{ sx: 'column', sm: 'row', md: 'row' }} flex="1" rowGap={1}>
            <Stack direction="column" spacing={0} alignItems="baseline">
              {user && <Typography variant="body2" component="span" display="block">#{user.accountNumber}</Typography>}
              <Typography variant="h6" component="h1" lineHeight={1}>
                {user?.businessName ?? t('telecom')}
              </Typography>
            </Stack>

            <Stack flex={1} direction="row" justifyContent={{ sx: 'flex-start', sm: 'flex-end'}}>
              {user && <Chip label={t('taxYearLabel', { taxYear: user.taxYear })} color="info" variant="filled" />}
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      {user?.delinquentTaxYears?.length > 0 && showDelinquentNoticeBanner && (
        <DelinquentBanner delinquentTaxYears={user.delinquentTaxYears} onClose={onDismissDelinquentBannerClose} />
      )}

      {user && (
        <NavigationDrawer
          user={user}
          open={isDrawerOpen}
          onDrawerClose={onDrawerClose}
        />
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flexGrow: 1,
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    marginBottom: theme.spacing(4),
  },
  appBar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
    },
  },
}))
