// Utilities File
import numbro from 'numbro'

export type RequiredAll<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

export function formatPercentage(percentage: number): string {
  return `${(percentage).toFixed(2)}%`
}

export function removeItem<T>(array: T[], index: number): T[] {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export interface HasId<Id> {
  id: Id
}

export function hasSameId<Id, T extends HasId<Id>>(first: T, second: T): boolean {
  return first.id === second.id
}

export function debounce<Params extends unknown[]>(
  fn: (...args: Params) => unknown,
  delay = 300,
): (...args: Params) => void {
  let timeoutId: ReturnType<typeof setTimeout>

  return (...args: Params): void => {

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      fn.apply(fn, args)
    }, delay)
  }
}

export interface HasLabel {
  label: string
}

export function getLabel<T extends HasLabel>(value: T): string {
  return value.label
}

export function ordinal(index: number): string {
  const ordianlFormat: numbro.Format = { output: 'ordinal' }

  return numbro(index + 1).format(ordianlFormat)
}

export function formatMoney(money: number): string {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(money)
}

export function formatMoneyNoDecimals(money: number): string {
  return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(money)
}
