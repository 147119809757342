import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

export default function FakePaginator(): JSX.Element {

  const classes = useStyles()

  return <div className={classes.root} />
}

const useStyles = makeStyles({ root: { height: 32 } })
