import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide, { SlideProps } from '@mui/material/Slide'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { OathStatement } from '../../generated/telecomGraphqlService'

import { formatMoneyNoDecimals, formatPercentage } from '../../utils'

interface Props {
  isOpen: boolean
  oathStatement: OathStatement
  onClose(): void
  onAffirm(): void
  onDismiss(): void
}

export default function OathDialog(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { oathStatement } = props

  const tOptions = {
    taxYear: oathStatement.taxYear,
    exemptionLimit: formatMoneyNoDecimals(oathStatement.exemptionLimit as number),
    filingDueDate: format(new Date(oathStatement.filingDueDate as string), DATE_FORMAT),
    interestRate: formatPercentage(oathStatement.interestRate as number),
    penaltyAmount: oathStatement.penaltyAmount,
    penaltyPercent: oathStatement.penaltyPercent,
  }

  const onDismiss = (): void => {
    props.onDismiss()
    props.onClose()
  }

  const onAffirm = (): void => {
    props.onAffirm()
    props.onClose()
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      aria-describedby="oath-dialog"
      keepMounted
    >
      <DialogTitle>{t('countiesOath')}</DialogTitle>

      <DialogContent>
        <DialogContentText id="oath-dialog" component="div">
          <Trans
            i18nKey="electronicSubmissionTermsAndCondition"
            tOptions={tOptions}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onDismiss}>
          {t('dismiss')}
        </Button>
        <Button onClick={onAffirm} color="primary">
          {t('affirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DATE_FORMAT = 'MMM dd, yyyy'

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
