import {
  Modal,
  Fade,  
  CircularProgress,
  Theme, 
  Button} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'

interface modalProps  {
  isOpen?:() => void,
  onClose?: () => void,
  imageUrl: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'transparent',    
    padding: theme.spacing(2, 4, 3),
    maxWidth: '100%',
    maxHeight: '100%',
  },
}))

const ImageModal = (props:modalProps): JSX.Element => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  
  const handleClose = ():void => {
    props.onClose?.()
    setOpen(false)
  }
  
  const handleOpen = ():void => {
    props.isOpen?.()
    setOpen(true)
  }

  const handleImageLoad = ():void => {
    setIsImageLoaded(true)
  }

  return (
    <div>
      <Button 
        variant="outlined"
        color="inherit"
        onClick={handleOpen}>Example Document
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in= {open}>
          <div className={classes.paper}>           
            <img
              src={props.imageUrl}
              alt="Modal image"
              onLoad={handleImageLoad}
              style={{
                display: 'flex',
                maxWidth: '100%',
                height: 'auto',
              }}
              onClick={handleClose}
            /> 
            {!isImageLoaded && (
              <div className="MuiCircularProgress-root">
                <CircularProgress />
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
export default ImageModal
