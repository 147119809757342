import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Theme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Panel } from 'shared-components'
import { Exact, RemovePropertyAssetListMutation, useGetExampleFileQuery } from '../../generated/telecomGraphqlService'

import { ReactComponent as CsvSVG } from '../../svgs/csv.svg'
import { GoToPage } from '../../utils/routeActions'
import ImageModal from '../ImageModal/ImageModal'
import DragAndDropMessage from './DragAndDropMessage'
import File from './File'
import ValidationReportList from './ValidationReportList'

interface Props {
  onSubmit: (file: File) => void
  downloadTemplateURL?: string
  previousFileURL?: string | null
  validationReport?: string | null
  validationReportName?: string | null
  removeValidationReport?: () => void
  isLoading?: boolean
  hasErrors?: boolean
  isSubmitting?: boolean
  shouldShowSkipButton?: boolean
  handleSkip?: () => void
  fileIconName?: string
  onDismiss?: () => Promise<void>
  setHasFormErrors?: React.Dispatch<React.SetStateAction<boolean>>
  onRemoveFile?: UseMutateAsyncFunction<RemovePropertyAssetListMutation, unknown, Exact<{
    [key: string]: never;
}>, unknown>
  isRemovingFile?: boolean
  navigateToNextStep: GoToPage
  isLeased?: boolean
}

export default function DragableFileUpload(props: Props): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()
  const formRef = React.useRef<HTMLFormElement>(null)
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const [fileList, setFileList] = React.useState<FileList | null>(null)
  const [previousFileURL, setPreviousFileURL] = React.useState<string | null | undefined>(props.previousFileURL)
  const [isFileDragged, setIsFileDragged] = React.useState(false)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = React.useState('')
  const { data } = useGetExampleFileQuery({ isLeased: props.isLeased })  


  React.useEffect(() => {
    if (!props.isLoading) {
      setPreviousFileURL(props.previousFileURL)
    }
  }, [props.isLoading, props.previousFileURL])

  React.useEffect(() => {
    if (props.validationReport) {
      formRef.current?.reset()
    }
  }, [props.validationReport])

  const handleDismiss = async (): Promise<void> => {
    setFileList(null)
    await props.onDismiss?.()
  }

  const handleDragEnter = (): void => {
    setIsFileDragged(true)
  }

  const handleDragLeave = (): void => {
    setIsFileDragged(false)
  }

  const removeValueFromInput = (): void => {
    Object.defineProperty(fileInputRef?.current, 'value', {
      writable: true,
      value: '',
    })
  }

  const handleRemoveFile = (): void => {
    setFileList(null)
    formRef?.current?.reset()
    props.setHasFormErrors?.(false)
  }


  const fileUploadRetry = async (): Promise<void> => {
    try {
      props.onSubmit((fileList as FileList)[0])      
    } catch (error) {
      removeValueFromInput()
      setSnackbarErrorMessage(t('oopsSomethingWentWrong'))
    }
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = e.target.files as FileList
    const file = files[0]

    setIsFileDragged(false)

    if (!file) {
      return
    }

    if (file.type !== 'text/csv') {      
      setSnackbarErrorMessage(t('wrongFileExtensionPleaseSelectCSVFilesOnly'))
      handleRemoveFile()
      return
    }

    setFileList(files)
    setPreviousFileURL(null)
    props.removeValidationReport?.()
    removeValueFromInput()

    try {
      props.onSubmit(file)
    } catch (error) {
      removeValueFromInput() 
      setSnackbarErrorMessage(t('oopsSomethingWentWrong'))   
    }    
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarErrorMessage('')
  }

  const handleRemovePreviousFile = async (): Promise<void> => {
    if(props.isLeased){
      await props.onDismiss?.()
    } else {
      await props.onRemoveFile?.({})
    }    
    handleRemoveFile()
    setPreviousFileURL(null)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (previousFileURL && props.validationReport) {
      setSnackbarErrorMessage(t('weFoundSomeErrorsPleaseCheckTheReport'))
      handleRemoveFile()
      formRef.current?.reset()
      return
    }

    if (previousFileURL && !props.validationReport) {
      props.navigateToNextStep(navigate)
      return
    }

    if (!previousFileURL) {
      setSnackbarErrorMessage(t('youMustSelectACSVFile'))


    }
  }

  if (props.isLoading) {
    return (
      <Box className={classes.initialLoader}>
        <CircularProgress />
      </Box>
    )
  }

  const hasFileAttached = fileList && !previousFileURL
  const hasNoFilesAtAll = !fileList && !previousFileURL
  const hasPdfError = !!props.validationReport
  const fileName = hasFileAttached ? fileList[0].name : (props.fileIconName as string)

  return (
    <Stack spacing={6}>
      <Panel
        classes={{ root: classes.panelRoot }}
        endContent={          
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end">
            <ImageModal imageUrl={ data?.getExampleFile?.fileName || ''} />
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<CloudDownloadIcon />}
              href={props.downloadTemplateURL}
              disabled={!props.downloadTemplateURL}
            >
              {t('downloadTemplate')}
            </Button>
          </Stack>          
        }
      >
        <Stack direction={{ sx: 'column', sm: 'row' }} spacing={{ xs: 4, sm: 1, md: 6 }}>
          {!!props.validationReport && !!props.validationReportName && (
            <ValidationReportList
              fileIconName={props.fileIconName as string}
              previousFileURL={props.previousFileURL}
              validationReport={props.validationReport}
              validationReportName={props.validationReportName}
            />
          )}

          <Stack
            id="asset-list-form"
            ref={formRef}
            className={`${classes.dragableBox} ${isFileDragged ? classes.dragableActive : ''}`}
            component="form"
            spacing={{ xs: 2, md: 4 }}
            onSubmit={handleSubmit}
          >
            {props.isSubmitting && (
              <Box className={classes.loaderWrapper}>
                <LinearProgress />
              </Box>
            )}

            <input
              type="file"
              accept=".csv, text/csv"
              ref={fileInputRef}
              className={classes.inputFile}
              onChange={handleFileChange}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragEndCapture={handleDragLeave}
              data-testid="inputFile"
              draggable
            />

            {hasNoFilesAtAll || hasPdfError ? (
              <DragAndDropMessage />
            ) : (
              <File
                icon={<CsvSVG width={30}/>}
                label={fileName}
                downloadURL={
                  hasFileAttached ? URL.createObjectURL(fileList[0]) : (props.previousFileURL as string)
                }
                downloadName={fileName}
                onRemoveClick={hasFileAttached ? handleRemoveFile : handleRemovePreviousFile}
                removeFile={handleRemoveFile}
                isSubmitting={!!props.isSubmitting}
                retrySubmit={fileUploadRetry}
                isSaved={!!previousFileURL}
                hasError={props.hasErrors}
                isRemovingFile={props.isRemovingFile}
              />
            )}
          </Stack>
        </Stack>
      </Panel>

      <Stack spacing={2} direction="row" justifyContent="flex-end">
        {props.shouldShowSkipButton &&
          (!previousFileURL ? (
            <Button variant="text" onClick={props.handleSkip} disabled={props.isSubmitting}>
              {t('skipAssets')}
            </Button>
          ) : (
            <Button variant="text" onClick={handleDismiss} disabled={props.isSubmitting}>
              {t('dismiss')}
            </Button>
          ))}

        <Button variant="contained" type="submit" form="asset-list-form" disabled={props.isSubmitting}>
          {t('nextStep')}
        </Button>
      </Stack>

      <Snackbar open={!!snackbarErrorMessage} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error" onClose={handleClose}>
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dragableBox: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',    
    padding: theme.spacing(1.5),
    border: `1px dashed ${theme.palette.grey[300]}`,
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  dragableActive: {
    background: '#fff',
  },
  inputFile: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'blue',
    opacity: 0,
    cursor: 'pointer',
  },
  loaderWrapper: {
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, .25)',
    textAlign: 'center',
    cursor: 'progress',
  },
  initialLoader: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
  },
  panelRoot: {
    display: 'block',
    borderRadius: 4,
    height: 'auto',
    overflow: 'hidden',
  },
  colorWhite: {
    color: theme.palette.primary.contrastText,
  },
}))
