import { CountySettings, OATHType } from '../models/api'
import { countySettings, contactList, oathStatement } from './mocks'
import { AnyContactType } from './validations'

export async function getCountySettings(): Promise<CountySettings> {
  // TODO: Replace this logic with a call to the BE to get the county settings
  // https://mcatcama.visualstudio.com/Puma/_workitems/edit/5392

  if (process.env.NODE_ENV !== 'test') {
    await new Promise((resolve) => setTimeout(resolve, 200))
  }

  return countySettings
}

export async function fetchContactList(): Promise<AnyContactType[]> {
  // TODO: Replace this logic with a call to the BE to get the Contact List entity
  // https://mcatcama.visualstudio.com/Puma/_workitems/edit/6419
  if (process.env.NODE_ENV !== 'test') {
    await new Promise((resolve) => setTimeout(resolve, 200))
  }

  return contactList
}

export async function getOATHStatement(): Promise<OATHType> {
  // TODO: Replace this logic with a call to the BE to post the getOATHStatement query
  // https://mcatcama.visualstudio.com/Puma/_workitems/edit/8387

  return Promise.resolve(oathStatement)
}

export async function putFileInAzureStorage(url: string, file: File): Promise<Response> {
  const response = await fetch(url, {
    body: file,
    method: 'PUT',
    headers: {
      'x-ms-blob-type': 'BlockBlob',
    },
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response
}
