import {
  Account,
  Address,
  GetLeaseAssetListQuery,
  GetPropertyAssetListQuery,
  ContactType,
  GetSummaryQuery,
  NonPrimaryContact,
  PrimaryContact,
  SaveLeaseAssetListMutation,
  SavePropertyAssetListMutation,
  State,
  ThankYouType,
  LoginMutation,
  GetOathStatementQuery,
  SessionContextQuery,
  GetExampleFileQuery,
} from '../generated/telecomGraphqlService'
import {
  AuthInformation,
  BusinessAssetsFilingStep,
  CountySettings,
  Summary,
  OATHType,
  User,
  ValidAccount,
} from '../models/api'
import { AnyContactType } from './validations'

export const countySettings: CountySettings = {
  name: 'Utah County',
  supportUrl: 'https://www.utahcounty.gov/ContactUs/Index.asp',
  paymentUrl: null,
}

// Completed Steps
export const noFinishedSteps: BusinessAssetsFilingStep[] = []

export const completedAccountInformation: BusinessAssetsFilingStep[] = [
  BusinessAssetsFilingStep.AccountInformation,
]

export const completedPropertyAssetList: BusinessAssetsFilingStep[] = [
  BusinessAssetsFilingStep.AccountInformation,
  BusinessAssetsFilingStep.PropertyAssetList,
]

export const completedLeasedAssetList: BusinessAssetsFilingStep[] = [
  BusinessAssetsFilingStep.AccountInformation,
  BusinessAssetsFilingStep.PropertyAssetList,
  BusinessAssetsFilingStep.LeasedAssetList,
]
export const allCompletedSteps: BusinessAssetsFilingStep[] = [
  BusinessAssetsFilingStep.AccountInformation,
  BusinessAssetsFilingStep.PropertyAssetList,
  BusinessAssetsFilingStep.LeasedAssetList,
  BusinessAssetsFilingStep.SummaryAndSignature,
]

// Contact Types
const firstContactType: ContactType = { id: '1', label: 'Contact Type' }
const accountantType: ContactType = { id: '2', label: 'Accountant' }
const primaryType: ContactType = { id: '3', label: 'Primary' }

export const contactTypes: ContactType[] = [firstContactType, accountantType, primaryType]

// User Accounts
const newAccountId = 'New Account'

export const newUser: User = {
  accountNumber: newAccountId,
  businessName: 'Noodles & Company',
  taxYear: 2021,
  completedSteps: noFinishedSteps,
  delinquentTaxYears: ['2021'],
}

export const completedAccountInformationUser: User = {
  ...newUser,
  accountNumber: 't01',
  completedSteps: completedAccountInformation,
}

export const completedPropertyAssetListUser: User = {
  ...newUser,
  accountNumber: 't02',
  completedSteps: completedPropertyAssetList,
}

export const completedLeasedAssetListUser: User = {
  ...newUser,
  accountNumber: 't03',
  completedSteps: completedLeasedAssetList,
}

export const finishedFilingUser: User = {
  ...newUser,
  accountNumber: 't04',
  completedSteps: allCompletedSteps,
}

// Telecom Account Information With Required Fields
const primaryContactMailingAddress: Address = {
  inCareOf: null,
  addressLine1: '4245 Riverdale Rd',
  addressLine2: null,
  city: 'Ogden',
  state: State.Ut,
  zipCode: '84405',
}

const primaryContact: PrimaryContact = {
  id: '777',
  name: 'John Doe',
  email: 'john.doe@noodles.com',
  phone: '(801) 621-4180',
  phoneExtension: null,
  cellphone: null,
  mailingAddress: primaryContactMailingAddress,
  contactType: primaryType,
}

export const validAccount: ValidAccount = {
  id: 'Valid Account',
  yearOpened: null,
  businessDescription: null,
  registeredEntityName: null,
  employerIdentificationNumber: null,
  primaryContact,
  otherContacts: [],
}

// New Telecom Accounts With Empty Fields
export const newAccount: Account = {
  id: newAccountId,
  yearOpened: null,
  businessDescription: null,
  registeredEntityName: null,
  employerIdentificationNumber: null,
  primaryContact: primaryContact,
  otherContacts: [],
}

// Telecom Account With All Fields Filled
const primaryContactMailingAddressFull: Address = {
  inCareOf: 'Five 9s Communications',
  addressLine1: '640 W 1100',
  addressLine2: 'Suite #5',
  city: 'Ogden',
  state: State.Ut,
  zipCode: '84404',
}

const nonPrimaryContactMailingAddressFull: Address = {
  inCareOf: 'Utah Communications',
  addressLine1: '575 NW 1300',
  addressLine2: 'Suite #2',
  city: 'Salt City Lake',
  state: State.Ut,
  zipCode: '84111',
}

const primaryContactFull: PrimaryContact = {
  id: '888',
  name: 'John Smith',
  email: 'john.smith@telecom.com',
  phone: '(801) 621-8324',
  phoneExtension: '8565',
  cellphone: '(801) 633-1502',
  mailingAddress: primaryContactMailingAddressFull,
  contactType: primaryType,
}

const nonPrimaryContactFull: NonPrimaryContact = {
  id: '777',
  name: 'Bruce Wayne',
  email: 'bruce.wayne@wayne.com',
  phone: '(801) 865-7658',
  phoneExtension: '8565',
  cellphone: '(801) 567-3014',
  mailingAddress: nonPrimaryContactMailingAddressFull,
  contactType: accountantType,
}

export const validAccountFull: ValidAccount = {
  id: 'Valid Full Account',
  yearOpened: 1992,
  businessDescription: 'Awesome Utah Telecom Account',
  registeredEntityName: 'Utah Telecom L.L.C.',
  employerIdentificationNumber: '12-3456789',
  primaryContact: primaryContactFull,
  otherContacts: [nonPrimaryContactFull],
}

export const errorOnGetAccount = 'Error On GetAccount'
export const errorOnGetSummary = 'Error On GetSummary'

export const graphqlInternalServerError = [
  {
    message: 'Internal Server Error',
    locations: [
      {
        line: 8,
        column: 12,
      },
    ],
    extensions: {
      code: 'GRAPHQL_INTERNAL_SERVER_ERROR',
      exception: {
        stacktrace: ['GraphQLError: Dummy Stacktrace'],
      },
    },
  },
]

// Sumary Page
export const summary: Summary = {
  assetsDeclared: {
    units: 14,
    cost: 1000,
    value: 14000,
  },
  netTotal: {
    units: 1,
    cost: 23500,
    value: 45000,
  },
  propertyAssetListURL: 'https://dummy.pdf',
}

export const contactList: AnyContactType[] = [
  {
    id: '1',
    name: 'Primary Contact Name',
    // contactType: firstContactType,
    email: 'primary.contact@test.com',
    phone: '9204567894',
    phoneExtension: null,
    cellphone: null,
    isPrimary: true,
    mailingAddress: primaryContactMailingAddress,
  },
  {
    id: '2',
    name: 'John Doe',
    // contactType: firstContactType,
    email: 'john.doe@test.com',
    phone: '9205234567',
    phoneExtension: null,
    cellphone: null,
    isPrimary: false,
    mailingAddress: null,
  },
  {
    id: '3',
    name: 'Richard Doe',
    // contactType: accountantType,
    email: 'richard.doe@test.com',
    phone: '9207891234',
    phoneExtension: null,
    cellphone: null,
    isPrimary: false,
    mailingAddress: null,
  },
]

export const oathStatement: OATHType = {
  taxYear: 2021,
  exemptionLimit: 25000.0,
  filingDueDate: '2022-05-16T00:00:00',
  interestRate: 7.0,
  interestStartDate: '2022-05-17T00:00:00',
  countyAppealFormURL: null,
  userInfo: null,
}

// #getProperyAssetList
export const getPropertyAssetListDataWithFilename: GetPropertyAssetListQuery = {
  getPropertyAssetList: {
    accountNumber: 't01',
    fileName: '936a86cc-ba98-4e66-be70-c408af0281fb.csv',
    fileUrl:
      'https://personalproperty.blob.core.windows.net/test2/2022/936a86cc-ba98-4e66-be70-c408af0281fb.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReport: null,
    validationReportName: null,
    templateURL:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Template_NonLease.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=yFYu1ZVDz5vgwLh1Le3C15yCV5gslFCi1dgvJRVlZXc%3D',
  },
}

export const getPropertyAssetListDataWithValidationReport: GetPropertyAssetListQuery = {
  getPropertyAssetList: {
    accountNumber: 't02',
    fileName: '936a86cc-ba98-4e66-be70-c408af0281fb.csv',
    fileUrl:
      'https://personalproperty.blob.core.windows.net/test2/2022/936a86cc-ba98-4e66-be70-c408af0281fb.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReportName: '936a86cc-ba98-4e66-be70-c408af0281fb_Error.pdf',
    validationReport:
      'https://personalproperty.blob.core.windows.net/test2/2022/936a86cc-ba98-4e66-be70-c408af0281fb_Error.pdf?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    templateURL:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Template_NonLease.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=yFYu1ZVDz5vgwLh1Le3C15yCV5gslFCi1dgvJRVlZXc%3D',
  },
}

export const getPropertyAssetListDataNoPreviousData: GetPropertyAssetListQuery = {
  getPropertyAssetList: {
    accountNumber: 't03',
    fileName: '',
    fileUrl: '',
    validationReportName: '',
    validationReport: '',
    templateURL:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Template_NonLease.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=yFYu1ZVDz5vgwLh1Le3C15yCV5gslFCi1dgvJRVlZXc%3D',
    countyContactsURL:
      'https://personalproperty.blob.core.windows.net/test2/2023/County_Contact_List.pdf?sv=2021-10-04&se=2023-02-24T03%3A04%3A50Z&sr=b&sp=r&sig=hqgYLhtY6Cfsdokj7w%2FQUS%2BDWAuC5Q4VDeboEK7%2B7vk%3D',
  },
}

// #saveProperyAssetList
export const savePropertyAssetListDataWithFilename: SavePropertyAssetListMutation = {
  savePropertyAssetList: {
    accountNumber: 't01',
    fileName:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReport: null,
  },
}

export const savePropertyAssetListDataWithValidationReport: SavePropertyAssetListMutation = {
  savePropertyAssetList: {
    accountNumber: 't02',
    fileName:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReport:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4_Error.pdf?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
  },
}

export const savePropertyAssetListDataNoPreviousData: SavePropertyAssetListMutation = {
  savePropertyAssetList: {
    accountNumber: 't03',
    fileName: '',
    validationReport: '',
  },
}

// #generatePresignedUploadCsvFile
export const generatePresignedUploadCsvFileData = {
  generatePresignedUploadCsvFile: {
    url: 'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv?sv=2021-10-04&se=2022-10-27T02%3A36%3A08Z&sr=b&sp=cw&sig=bwRzNGHdTtC6QY4ElFZEWm7p1UVIOteAm%2BZDdyzL14s%3D',
    fileName: 'f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv',
  },
}

export const putFileInAzureStorageResponseSuccess = new Response(null, {
  headers: new Headers(),
  status: 201,
  statusText: 'Created',
})

export const putFileInAzureStorageResponseError = new Response(null, {
  headers: new Headers(),
  status: 500,
  statusText: 'Internal Server Error',
})

export const mockedAuthInformation: AuthInformation = {
  token: 'validJwtToken',
  userId: 1,
  taxYear: 2021,
  exp: (Date.now() * 2) / 1000,
}

//#Lease Asset List
//Save Lease Asset List
export const saveLeaseAssetListDataNoPreviousData: SaveLeaseAssetListMutation = {
  saveLeaseAssetList: {
    accountNumber: 't03',
    fileName: '',
    validationReport: '',
  },
}

export const saveLeaseAssetListDataWithFilename: SaveLeaseAssetListMutation = {
  saveLeaseAssetList: {
    accountNumber: 't05',
    fileName:
      'https://personalproperty.blob.core.windows.net/test2/2022/936a86cc-ba98-4e66-be70-c408af0281fb.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReport: null,
  },
}

export const saveLeaseAssetListWithValidationReport: SaveLeaseAssetListMutation = {
  saveLeaseAssetList: {
    accountNumber: 't01',
    fileName:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReport:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4_Error.pdf?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
  },
}

//Get Lease Asset List
export const getLeaseAssetListDataWithFilename: GetLeaseAssetListQuery = {
  getLeaseAssetList: {
    accountNumber: 't01',
    fileName: '936a86cc-ba98-4e66-be70-c408af0281fb.csv',
    fileUrl:
      'https://personalproperty.blob.core.windows.net/test2/2022/936a86cc-ba98-4e66-be70-c408af0281fb.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReport: null,
    validationReportName: null,
    templateURL:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Template_Lease.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=rMMAoD6s1POqx4S8xI0LJqg2Ama%2F2VMeWMfi%2FLJezjY%3D',
  },
}

export const getLeaseAssetListNoPreviusData: GetLeaseAssetListQuery = {
  getLeaseAssetList: {
    accountNumber: 't02',
    fileName: '',
    fileUrl: '',
    validationReportName: '',
    validationReport: '',
    templateURL:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Template_Lease.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=rMMAoD6s1POqx4S8xI0LJqg2Ama%2F2VMeWMfi%2FLJezjY%3D',
  },
}

export const getLeaseAssetListWithValidationReport: GetLeaseAssetListQuery = {
  getLeaseAssetList: {
    accountNumber: 't03',
    fileName: 'f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv',
    fileUrl:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReportName: 'f2409176-180e-4dc6-80b3-8b316bf9eeb4_Error.pdf',
    validationReport:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4_Error.pdf?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    templateURL:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Template_Lease.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=rMMAoD6s1POqx4S8xI0LJqg2Ama%2F2VMeWMfi%2FLJezjY%3D',
  },
}

export const getLeaseAssetListWithOutValidationReport: GetLeaseAssetListQuery = {
  getLeaseAssetList: {
    accountNumber: 't04',
    fileName: 'f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv',
    fileUrl:
      'https://personalproperty.blob.core.windows.net/test2/2022/f2409176-180e-4dc6-80b3-8b316bf9eeb4.csv?sv=2021-10-04&se=2022-11-08T20%3A01%3A06Z&sr=b&sp=r&sig=GykUk5bv540fiekLlbGiONJHOB47wEkqAAhGVacW3kE%3D',
    validationReport: null,
    validationReportName: null,
    templateURL:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Template_Lease.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=rMMAoD6s1POqx4S8xI0LJqg2Ama%2F2VMeWMfi%2FLJezjY%3D',
  },
}

// #getSummary

export const getSummaryWithUrls: GetSummaryQuery = {
  getSummary: {
    accountNumber: 't02',
    propertyAssetListURL: {
      pdf: {
        filename: 'nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.pdf',
        fileUrl:
          'https://personalproperty.blob.core.windows.net/test2/2022/nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.pdf?sv=2021-10-04&se=2022-12-08T14%3A25%3A45Z&sr=b&sp=r&sig=ABMXD3jdbFU2OUA4SVaMbLGd%2BKloeozqJrXossjEvYI%3D',
      },
      csv: {
        filename: 'nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.csv',
        fileUrl:
          'https://personalproperty.blob.core.windows.net/test2/2022/nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.csv?sv=2021-10-04&se=2022-12-08T14%3A25%3A45Z&sr=b&sp=r&sig=ABMXD3jdbFU2OUA4SVaMbLGd%2BKloeozqJrXossjEvYI%3D',
      },
    },
    leasedAssetListURL: {
      pdf: {
        filename: 'lease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.pdf',
        fileUrl:
          'https://personalproperty.blob.core.windows.net/test2/2022/lease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.pdf?sv=2021-10-04&se=2022-12-08T14%3A25%3A45Z&sr=b&sp=r&sig=ABMXD3jdbFU2OUA4SVaMbLGd%2BKloeozqJrXossjEvYI%3D',
      },
      csv: {
        filename: 'lease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.csv',
        fileUrl:
          'https://personalproperty.blob.core.windows.net/test2/2022/lease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.csv?sv=2021-10-04&se=2022-12-08T14%3A25%3A45Z&sr=b&sp=r&sig=ABMXD3jdbFU2OUA4SVaMbLGd%2BKloeozqJrXossjEvYI%3D',
      },
    },
    assetDeclared: {
      units: 10,
      cost: 200,
      value: 2000,
    },
    netTotal: {
      value: 2000,
      cost: 200,
    },
    contacts: [
      {
        id: '1',
        name: 'John Doe',
        email: 'johndoe@personalproperty.com',
        phone: '(920) 789-1234',
        contactType: 'Contact',
      },
      {
        id: '2',
        name: 'Richard Doe',
        email: 'richard.doe@test.com',
        phone: '(920) 789-1234',
        contactType: 'Contact',
      },
    ],
  },
}

export const getSummaryWithNonLease: GetSummaryQuery = {
  getSummary: {
    accountNumber: 't01',
    leasedAssetListURL: null,
    propertyAssetListURL: {
      pdf: {
        filename: 'nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.pdf',
        fileUrl:
          'https://personalproperty.blob.core.windows.net/test2/2022/nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.pdf?sv=2021-10-04&se=2022-12-08T14%3A25%3A45Z&sr=b&sp=r&sig=ABMXD3jdbFU2OUA4SVaMbLGd%2BKloeozqJrXossjEvYI%3D',
      },
      csv: {
        filename: 'nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.csv',
        fileUrl:
          'https://personalproperty.blob.core.windows.net/test2/2022/nonlease-873564dc-c647-4c15-8975-1aca0a6baa80_Draft.csv?sv=2021-10-04&se=2022-12-08T14%3A25%3A45Z&sr=b&sp=r&sig=ABMXD3jdbFU2OUA4SVaMbLGd%2BKloeozqJrXossjEvYI%3D',
      },
    },
    countyContactsURL:
      'https://personalproperty.blob.core.windows.net/test2/2023/County_Contact_List.pdf?sv=2021-10-04&se=2023-02-24T03%3A04%3A50Z&sr=b&sp=r&sig=hqgYLhtY6Cfsdokj7w%2FQUS%2BDWAuC5Q4VDeboEK7%2B7vk%3D',
    assetDeclared: {
      units: 10,
      cost: 200,
      value: 2000,
    },
    netTotal: {
      value: 2000,
      cost: 200,
    },
    contacts: [
      {
        id: '1',
        name: 'John Doe',
        email: 'johndoe@personalproperty.com',
        phone: '(920) 789-1234',
        contactType: 'Contact',
      },
      {
        id: '2',
        name: 'Richard Doe',
        email: 'richard.doe@test.com',
        phone: '(920) 789-1234',
        contactType: 'Contact',
      },
    ],
  },
}

export const GetOathStatementResponse: GetOathStatementQuery = {
  getOathStatement: {
    taxYear: 2022,
    exemptionLimit: 25000.0,
    filingDueDate: '2022-05-16T00:00:00',
    interestRate: 7.0,
    penaltyAmount: '25',
    penaltyPercent: '10',
  },
}

export const validLoginData: LoginMutation = {
  login: {
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI3IiwiaXNBZG1pbiI6ZmFsc2UsImlzVGVsZWNvbVVzZXIiOnRydWUsInRlbGVjb21JZCI6IjIiLCJ0YXhZZWFyIjoiMiwwMjIiLCJleHAiOjE2NzI4NzE4ODgsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0MzAwIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDAifQ.oO3SdMy4GuhpxfGgB7FY6fXH5imeujnDD9cv6294V0U',
  },
}

export const invalidLoginData = {
  errors: [
    {
      message: 'Invalid account number or pin code',
      locations: [
        {
          line: 2,
          column: 3,
        },
      ],
      path: ['login'],
      extensions: {
        code: 'INTERNAL_SERVER_ERROR',
      },
    },
  ],
  // data: {
  //   login: null,
  // },
}

export const sessionContext: SessionContextQuery = {
  getSessionContext: {
    accountNumber: 't02',
    businessName: 'AT&T',
    stepsCompleted: [],
    taxYear: 2022,
    delinquentTaxYears: ['2021'],
  },
}

export const invalidAuthInformation: AuthInformation = {
  token: 'invalidJwtToken',
  userId: 1,
  taxYear: 2021,
  exp: (Date.now() * 2) / 1000,
}

export const expiredAuthInformation: AuthInformation = {
  token: 'invalidJwtToken',
  userId: 1,
  taxYear: 2021,
  exp: (Date.now() * 0.5) / 1000,
}

export const thankYouResponse: ThankYouType = {
  penaltyPercent: '10',
  taxYear: 2022,
  accountName: 'Telecom Inc',
  accountNumber: 'T04',
  filingDeadline: '2022-05-16T00:00:00',
  interestRate: 12.0,
  filingDate: 'May 02 2022',
  filingTime: '6:47 PM',
  leaseFile: {
    pdf: {
      filename: 'Lease_Telecom_Inc_Final.pdf',
      fileUrl:
        'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Lease_Telecom_Inc_Final.pdf?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=6p2VcZuWH7n01DjfsydJzNdI9IZLb8QCTtHf%2FDrdtXE%3D',
    },
    csv: {
      filename: 'Lease_Telecom_Inc_Final.csv',
      fileUrl:
        'https://testingstorage.blob.core.windows.net/testingcontainer/2022/Lease_Telecom_Inc_Final.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=6p2VcZuWH7n01DjfsydJzNdI9IZLb8QCTtHf%2FDrdtXE%3D',
    },
  },
  nonLeaseFile: {
    pdf: {
      filename: 'NonLease_Telecom_Inc_Final.pdf',
      fileUrl:
        'https://testingstorage.blob.core.windows.net/testingcontainer/2022/NonLease_Telecom_Inc_Final.pdf?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=6p2VcZuWH7n01DjfsydJzNdI9IZLb8QCTtHf%2FDrdtXE%3D',
    },
    csv: {
      filename: 'NonLease_Telecom_Inc_Final.csv',
      fileUrl:
        'https://testingstorage.blob.core.windows.net/testingcontainer/2022/NonLease_Telecom_Inc_Final.csv?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=6p2VcZuWH7n01DjfsydJzNdI9IZLb8QCTtHf%2FDrdtXE%3D',
    },
  },
  countyContactsURL:
    'https://personalproperty.blob.core.windows.net/test2/2023/County_Contact_List.pdf?sv=2021-10-04&se=2023-02-24T03%3A04%3A50Z&sr=b&sp=r&sig=hqgYLhtY6Cfsdokj7w%2FQUS%2BDWAuC5Q4VDeboEK7%2B7vk%3D',
}

export const thankYouResponseWithNoFilenames: ThankYouType = {
  ...thankYouResponse,
  leaseFile: {
    pdf: {
      filename: null,
      fileUrl: null,
    },
    csv: {
      filename: null,
      fileUrl: null,
    },
  },
  nonLeaseFile: {
    pdf: {
      filename: null,
      fileUrl: null,
    },
    csv: {
      filename: null,
      fileUrl: null,
    },
  },
}

export const getExampleFileWithValueLeased: GetExampleFileQuery = {
  getExampleFile: {
    fileName:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/exampleFileLease.png?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=h9iyYpY6GFwHiTF86UFOU3xHScWlK%2B1wgZwl3tv7Jt0%3D',
  },
}
export const getExampleFileWithValueNonLeased: GetExampleFileQuery = {
  getExampleFile: {
    fileName:
      'https://testingstorage.blob.core.windows.net/testingcontainer/2022/exampleFileNonLeased.png?sv=2021-10-04&se=2022-10-24T10%3A00%3A02Z&sr=b&sp=r&sig=h9iyYpY6GFwHiTF86UFOU3xHScWlK%2B1wgZwl3tv7Jt0%3D',
  },
}
